import { Box, Column, numericUtil, Row } from '@cmg/common';
import { participationSummarySelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { getFeatureToggles } from '../../../../config/appSettings';
import { IoiParticipation } from '../../../../types/domain/datalab/ioi-participation';
import { selectIoiParticipationCharts } from '../../ducks';
import SingleVerticalBarChart from '../widgets/charts/SingleVerticalBarChart';
import WidgetAftermarketPerformanceRelativeToIoI from '../widgets/WidgetAftermarketPerformanceRelativeToIoI/WidgetAftermarketPerformanceRelativeToIoI';
import WidgetAllocationPerformanceHeatMapBySector from '../widgets/WidgetAllocationPerformanceHeatMapBySector/WidgetAllocationPerformanceHeatMapBySector';
import WidgetHistoricalOfferingVolumeByType from '../widgets/WidgetHistoricalOfferingVolumeByType/WidgetHistoricalOfferingVolumeByType';
import WidgetHistoricalOfferingVolumeSnapshot from '../widgets/WidgetHistoricalOfferingVolumeSnapshot/WidgetHistoricalOfferingVolumeSnapshot';
import WidgetOfferingPerformance from '../widgets/WidgetOfferingPerformance/WidgetOfferingPerformance';
import WidgetOfferingTotal from '../widgets/WidgetOfferingTotal/WidgetOfferingTotal';
import WidgetOfferingVolumeBySector from '../widgets/WidgetOfferingVolumeBySector/WidgetOfferingVolumeBySector';
import WidgetPerformanceHeatMapBySector from '../widgets/WidgetPerformanceHeatMapBySector/WidgetPerformanceHeatMapBySector';

type Props = {
  datalab: IoiParticipation;
};

export class IoiParticipationScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;
    const { enableUseRestReports } = getFeatureToggles();

    return (
      <div data-test-id={participationSummarySelector.testId}>
        <Row>
          <Column xs={24} sm={6}>
            <Box>
              <WidgetOfferingTotal
                offerings={datalab.offerings}
                detailValue={datalab.invested_dollars}
                detailTitle="Invested Dollars"
              />
            </Box>
          </Column>

          <Column xs={24} sm={18}>
            <Box>
              <WidgetOfferingPerformance
                ipo={datalab.ipo}
                followOn={datalab.follow_on}
                info={datalab.offering_by_type_info}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24} sm={12}>
            <Box>
              <WidgetHistoricalOfferingVolumeByType
                chartData={datalab.participation_volume_by_type}
                title="Participation Volume by Type"
              />
            </Box>
          </Column>
          <Column xs={24} sm={12}>
            <Box>
              <WidgetOfferingVolumeBySector
                chartData={datalab.participation_volume_by_sector}
                title="Participation Volume by Sector"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetHistoricalOfferingVolumeSnapshot
                chartData={datalab.historical_participation_volume}
                title="Historical Offering Participation"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPerformanceHeatMapBySector
                chartData={datalab.performance_heat_map_by_sector}
                useRuby={!enableUseRestReports}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetAllocationPerformanceHeatMapBySector
                chartData={datalab.weighted_performance_heat_map_by_sector}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetAftermarketPerformanceRelativeToIoI
                chartData={datalab.after_market_relative_to_ioi}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <SingleVerticalBarChart
                rawData={datalab.percent_of_offerings_trading_above_offer}
                title="% Trading Above Offer"
                yLabelFormatter={value => numericUtil.formatPercents(value, 1)}
                valueFormatter={numericUtil.formatPercents}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <SingleVerticalBarChart
                rawData={datalab.first_day_turnover}
                title="First Day Turnover By Sector"
                yLabelFormatter={numericUtil.formatMultipleFactor}
                valueFormatter={value => numericUtil.formatNumber(value, 1)}
              />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectIoiParticipationCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(IoiParticipationScreenComponent);
