import { apiTypes, reduxUtil, ToastManager } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import api, {
  UnderwriterCreditsFetchResponse,
  UnderwriterCreditsSubmitData,
  UnderwriterCreditsSubmitSuccessData,
} from '../../../api/dl-ruby/datalab-api';
import { RootState } from '../../../common/redux/rootReducer';
import { OldApiResponse } from '../../../types/api/ApiResponse';
import { UUID } from '../../../types/common';
import { UnderwriterCreditsAllocation } from '../../../types/domain/underwriter-credits/underwriterCreditsAllocation';
import { UnderwriterCreditsManager } from '../../../types/domain/underwriter-credits/underwriterCreditsManager';
import { UnderwriterCreditsOffering } from '../../../types/domain/underwriter-credits/underwriterCreditsOffering';
import { toastMessages } from '../../shared/constants/messages';
import { getManagers } from './model/underwriter-credit.model';

const { createReducer } = reduxUtil;

export enum ActionTypes {
  FETCH_UNDERWRITER_CREDITS_REQUEST = 'underwriter-credit/FETCH_UNDERWRITER_CREDITS_REQUEST',
  FETCH_UNDERWRITER_CREDITS_SUCCESS = 'underwriter-credit/FETCH_UNDERWRITER_CREDITS_SUCCESS',
  FETCH_UNDERWRITER_CREDITS_FAILURE = 'underwriter-credit/FETCH_UNDERWRITER_CREDITS_FAILURE',
  SAVE_UNDERWRITER_CREDITS_REQUEST = 'underwriter-credit/SAVE_UNDERWRITER_CREDITS_REQUEST',
  SAVE_UNDERWRITER_CREDITS_SUCCESS = 'underwriter-credit/SAVE_UNDERWRITER_CREDITS_SUCCESS',
  RESET_STATE = 'underwriter-credit/RESET_STATE',
}

export const resetState = () => ({
  type: ActionTypes.RESET_STATE,
});

export const fetchUnderwriterCredits = (payload: { offeringId: UUID }) => ({
  type: ActionTypes.FETCH_UNDERWRITER_CREDITS_REQUEST,
  payload,
});

export const fetchUnderwriterCreditsSuccess = (payload: UnderwriterCreditsFetchResponse) => ({
  type: ActionTypes.FETCH_UNDERWRITER_CREDITS_SUCCESS,
  payload,
});

export const fetchUnderwriterCreditsFailure = (payload: apiTypes.GenericResponseDataFailure) => ({
  type: ActionTypes.FETCH_UNDERWRITER_CREDITS_FAILURE,
  payload,
});

export const saveUnderwriterCreditsRequest = (
  payload: { offeringId: UUID } & UnderwriterCreditsSubmitData
) => ({
  type: ActionTypes.SAVE_UNDERWRITER_CREDITS_REQUEST,
  payload,
});

export const saveUnderwriterCreditsSuccess = (payload: UnderwriterCreditsSubmitSuccessData) => ({
  type: ActionTypes.SAVE_UNDERWRITER_CREDITS_SUCCESS,
  payload,
});

type Actions = {
  [ActionTypes.FETCH_UNDERWRITER_CREDITS_REQUEST]: ReturnType<typeof fetchUnderwriterCredits>;
  [ActionTypes.FETCH_UNDERWRITER_CREDITS_SUCCESS]: ReturnType<
    typeof fetchUnderwriterCreditsSuccess
  >;
  [ActionTypes.FETCH_UNDERWRITER_CREDITS_FAILURE]: ReturnType<
    typeof fetchUnderwriterCreditsFailure
  >;
  [ActionTypes.SAVE_UNDERWRITER_CREDITS_REQUEST]: ReturnType<typeof saveUnderwriterCreditsRequest>;
  [ActionTypes.SAVE_UNDERWRITER_CREDITS_SUCCESS]: ReturnType<typeof saveUnderwriterCreditsSuccess>;
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
};

export type UnderwriterCreditsState = {
  error: apiTypes.GenericServerError | null;
  offering: UnderwriterCreditsOffering | null;
  allocation: UnderwriterCreditsAllocation | null;
  managers: UnderwriterCreditsManager[];
};

export const initialState: UnderwriterCreditsState = {
  error: null,
  offering: null,
  allocation: null,
  managers: [],
};

const offeringReducer = createReducer<UnderwriterCreditsState['offering'], Actions>(
  initialState.offering,
  {
    [ActionTypes.FETCH_UNDERWRITER_CREDITS_SUCCESS]: (state, { payload }) => payload.offering,
  }
);
export const allocationReducer = createReducer<UnderwriterCreditsState['allocation'], Actions>(
  initialState.allocation,
  {
    [ActionTypes.FETCH_UNDERWRITER_CREDITS_SUCCESS]: (state, { payload }) => payload.allocation,
    [ActionTypes.SAVE_UNDERWRITER_CREDITS_SUCCESS]: (state, { payload }) => payload.data.allocation,
  }
);
const managersReducer = createReducer<UnderwriterCreditsState['managers'], Actions>(
  initialState.managers,
  {
    [ActionTypes.FETCH_UNDERWRITER_CREDITS_SUCCESS]: (state, { payload }) => payload.managers,
    [ActionTypes.SAVE_UNDERWRITER_CREDITS_SUCCESS]: (state, { payload }) => payload.data.managers,
  }
);
const errorReducer = createReducer<UnderwriterCreditsState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_UNDERWRITER_CREDITS_FAILURE]: (state, { payload }) => payload.error,
});

const crossSliceReducer = createReducer<UnderwriterCreditsState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<UnderwriterCreditsState>({
  offering: offeringReducer,
  allocation: allocationReducer,
  managers: managersReducer,
  error: errorReducer,
});

export default function duckReducer(
  state: UnderwriterCreditsState = initialState,
  action: AnyAction
) {
  const intermediateState = combinedReducers(state, action);
  const finalState = crossSliceReducer(intermediateState, action);
  return finalState;
}

const selectState = (state: RootState) => state.underwriterCredits;
export const selectUnderwriterCreditsOffering = state => selectState(state).offering;
export const selectAllocation = state => selectState(state).allocation;
export const selectError = (state: RootState) => selectState(state).error;
export const selectManagers = createSelector(
  (state: RootState) => selectState(state).managers,
  getManagers
);

export function* fetchUnderwriterCreditsSaga({ payload }) {
  const resp = yield call(api.fetchUnderwriterCredits, payload);
  if (resp.ok) {
    yield put(fetchUnderwriterCreditsSuccess(resp.data.data));
  } else {
    yield put(fetchUnderwriterCreditsFailure(resp.data));
  }
}

export function* saveUnderwriterCreditsSaga({
  payload,
}: Actions[ActionTypes.SAVE_UNDERWRITER_CREDITS_REQUEST]): SagaIterator {
  const data = {
    allocation: payload.allocation,
    managers: payload.managers,
  };

  const resp: OldApiResponse<UnderwriterCreditsSubmitSuccessData> = yield call(
    api.submitUnderwriterCredits,
    payload.offeringId,
    data
  );

  if (resp.ok) {
    yield put(saveUnderwriterCreditsSuccess(resp.data));
    ToastManager.success(toastMessages.success.saved);
  } else {
    ToastManager.error(toastMessages.error.errorSaving);
  }
}

export function* underwriterCreditsSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_UNDERWRITER_CREDITS_REQUEST]>(
    ActionTypes.FETCH_UNDERWRITER_CREDITS_REQUEST,
    fetchUnderwriterCreditsSaga
  );
  yield takeLatest<Actions[ActionTypes.SAVE_UNDERWRITER_CREDITS_REQUEST]>(
    ActionTypes.SAVE_UNDERWRITER_CREDITS_REQUEST,
    saveUnderwriterCreditsSaga
  );
}
