import { DLGWDataLabTableRowCompat } from '@capital-markets-gateway/api-client-datalab-gateway';
import {
  countriesList,
  exchangeMicLabels,
  getCurrencySymbol,
  numericUtil,
  Option,
  timeUtil,
} from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import CurrencyFormatter from '../../../common/components/currency-formatter/CurrencyFormatter';
import { AdvancedFilterOperatorConfig } from '../../../common/components/form/advanced-filter/advanced-filter.model';
import PerformanceCurrency from '../../../common/components/format/performance-currency/PerformanceCurrency';
import PerformancePercents from '../../../common/components/format/performance-percents/PerformancePercents';
import { formatBoolean, formatNullableBoolean } from '../../../common/helpers/helpers';
import routeFactory from '../../../common/util/routeFactory';
import {
  currencyEnumFilterOptions,
  exchangeMicEnumFilterOptions,
  InternationalOfferingTypeLabels,
  offeringEntityStructureLabels,
  offeringPriceVsRangeLabels,
  offeringSecurityTypeLabels,
  timingOfLaunchLabels,
} from '../../../types/domain/offering/constants';
import { CornerstoneTooltip } from '../../shared/components/cornerstone/tooltip/CornerstoneTooltip';
import { CornerstoneType } from '../../shared/components/cornerstone/type/CornerstoneType';
import { createOptions } from '../../shared/model/utils';
import { DatalabScreens } from '../constants';
import {
  maxField,
  meanField,
  medianField,
  minField,
  sumField,
  SummaryField,
  totalField,
  yoyField,
} from '../summaryFields';
import HeaderLabelRenderer from './components/HeaderLabelRenderer';
import LeftLeadFirmNameRenderer from './components/LeftLeadFirmNameRenderer';
import ShareholderRenderer, { Row } from './components/ShareholderRenderer';
import { isInternationalOfferingsOn, isInternationalOfferingTypesOn } from './utils';

const SNoWrap = styled.span`
  white-space: nowrap;
`;

export type DatalabTableColumn = {
  field: string;
  label: string;
  filterType?: FilterTypes;
  renderer?: (value: any, row?: any) => React.ReactNode;
  headerRenderer?: () => React.ReactNode;
  summaryConfig?: SummaryField[];
  fixedMinWidth?: number;
  disableSort?: boolean;
  filterEnum?: Option[];
  textAlign?: string;
  exportFields?: string[];
  operatorsConfig?: AdvancedFilterOperatorConfig[];
};

export enum FilterTypes {
  STRING = 'string',
  NUMBER = 'number',
  CURRENCY = 'currency',
  INTEGER = 'integer',
  PERCENT = 'percent',
  DATE = 'date',
  ENUM = 'enum',
  BOOLEAN = 'boolean',
  NULLABLE_BOOLEAN = 'nullable_boolean',
}

const dateTableColumn = {
  renderer: v => timeUtil.formatAsDisplayDate(v),
  filterType: FilterTypes.DATE,
};

const percent1digitTableColumn = {
  renderer: v => numericUtil.formatPercents(v, 1),
  filterType: FilterTypes.PERCENT,
  textAlign: 'right',
};

const percent2digitTableColumn = {
  renderer: v => numericUtil.formatPercents(v),
  filterType: FilterTypes.PERCENT,
  textAlign: 'right',
};

const percentPerformanceTableColumn = {
  renderer: v => <PerformancePercents value={v} />,
  filterType: FilterTypes.PERCENT,
  textAlign: 'right',
};

const currencyTableColumn = {
  renderer: v => numericUtil.formatCurrency(v),
  filterType: FilterTypes.CURRENCY,
  textAlign: 'right',
};

const millionCurrencyTableColumn = {
  ...currencyTableColumn,
  renderer: v => numericUtil.formatCurrencyInMillions(v),
};

const localCurrencyTableColumn = {
  disableSort: true,
  renderer: (value, row) =>
    value === null || value === undefined || !row.pricing_currency_code ? null : (
      <CurrencyFormatter
        value={value}
        currency={row.pricing_currency_code}
        displayVariant="symbol"
      />
    ),
  filterType: FilterTypes.NUMBER,
  textAlign: 'right',
};

const millionLocalCurrencyTableColumn = {
  ...localCurrencyTableColumn,
  renderer: (value, row) =>
    value === null || value === undefined || !row.pricing_currency_code
      ? null
      : numericUtil.formatCurrencyInMillions(
          value,
          undefined,
          getCurrencySymbol(row.pricing_currency_code)
        ),
};

const integerTableColumn = {
  renderer: v => numericUtil.formatInteger(v),
  filterType: FilterTypes.INTEGER,
  textAlign: 'right',
};

const booleanTableColumn = {
  renderer: v => formatBoolean(v),
  filterType: FilterTypes.BOOLEAN,
  textAlign: 'center',
};

const nullableBooleanTableColumn = {
  renderer: v => formatNullableBoolean(v),
  filterType: FilterTypes.NULLABLE_BOOLEAN,
  textAlign: 'center',
};

export const dtc = {
  offering_confidential_filing_date: {
    ...dateTableColumn,
    field: 'offering_confidential_filing_date',
    label: 'Confidential Filing Date',
  },
  offering_public_filing_date: {
    ...dateTableColumn,
    field: 'offering_public_filing_date',
    label: 'Public Filing Date',
  },
  offering_launch_date: {
    ...dateTableColumn,
    field: 'offering_launch_date',
    label: 'Launch Date',
  },
  timing_of_launch: {
    field: 'timing_of_launch',
    label: 'Timing of Launch',
    filterType: FilterTypes.ENUM,
    filterEnum: createOptions(timingOfLaunchLabels),
    renderer: value => timingOfLaunchLabels[value],
  },
  offerings_pricing_date: {
    ...dateTableColumn,
    field: 'offerings_pricing_date',
    label: 'Pricing Date',
  },
  offering_first_trade_date: {
    ...dateTableColumn,
    field: 'offering_first_trade_date',
    label: 'First Trade Date',
  },
  offering_settlement_date: {
    ...dateTableColumn,
    field: 'offering_settlement_date',
    label: 'Settlement Date',
  },
  companies_issuer: {
    field: 'companies_issuer',
    label: 'Issuer',
    renderer: (v, row) => {
      if (
        row?.offering_status?.toUpperCase() === 'POSTPONED' ||
        // country_code is not available
        // and all offerings are US offerings if International is not on
        ((!isInternationalOfferingsOn() || row.country_code === 'US') &&
          row?.offering_status?.toUpperCase() === 'FILED')
      ) {
        return v;
      }
      return <Link to={routeFactory.offerings.getUrlPath({ id: row.offering_id })}>{v}</Link>;
    },
    filterType: FilterTypes.STRING,
  },
  companies_ticker: {
    field: 'companies_ticker',
    label: 'Ticker',
    filterType: FilterTypes.STRING,
  },
  companies_cik: {
    field: 'companies_cik',
    label: 'CIK',
    fixedMinWidth: 85,
    filterType: FilterTypes.STRING,
  },
  companies_cusip: {
    field: 'companies_cusip',
    label: 'CUSIP',
    disableSort: true,
    fixedMinWidth: 85,
    filterType: FilterTypes.STRING,
  },
  issuer_isin: {
    field: 'issuer_isin',
    label: 'ISIN',
    disableSort: true,
    fixedMinWidth: 110,
    filterType: FilterTypes.STRING,
  },
  offering_entity_structure: {
    field: 'offering_entity_structure',
    label: 'Structure',
    fixedMinWidth: 85,
    filterType: FilterTypes.ENUM,
    filterEnum: createOptions(offeringEntityStructureLabels),
  },
  offerings_sector: {
    field: 'offerings_sector',
    label: 'Sector',
    filterType: FilterTypes.STRING,
  },
  companies_sub_sector: {
    field: 'companies_sub_sector',
    label: 'Sub Sector',
    filterType: FilterTypes.STRING,
  },
  offerings_type: {
    field: 'offerings_type',
    label: 'Offering Type',
    filterType: FilterTypes.STRING,
    renderer: (v, row) => {
      if (isInternationalOfferingTypesOn() && row.pricing_currency_code !== 'USD') {
        if (row?.offerings_type?.toUpperCase() === 'MARKETED FO') {
          return InternationalOfferingTypeLabels['MARKETED_FO'];
        }
        if (row?.offerings_type?.toUpperCase() === 'OVERNIGHT FO') {
          return InternationalOfferingTypeLabels['OVERNIGHT_FO'];
        }
        if (
          row?.offerings_type?.toUpperCase() === 'REGISTERED BLOCK' ||
          row?.offerings_type?.toUpperCase() === 'UNREGISTERED BLOCK'
        ) {
          return InternationalOfferingTypeLabels['REGISTERED_BLOCK'];
        }
        return v;
      }
      return v;
    },
  },
  offering_security_type: {
    field: 'offering_security_type',
    label: 'Security Type',
    filterType: FilterTypes.ENUM,
    filterEnum: createOptions(offeringSecurityTypeLabels),
    renderer: value => offeringSecurityTypeLabels[value],
  },
  filing_details_offering_price: {
    ...currencyTableColumn,
    field: 'filing_details_offering_price',
    label: 'Offer Price',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  filing_details_net_price: {
    ...currencyTableColumn,
    field: 'filing_details_net_price',
    label: 'Net Price',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_size: {
    ...integerTableColumn,
    field: 'offering_size',
    label: 'Total Shares',
  },
  total_shares_filed_excl_shoe: {
    ...integerTableColumn,
    field: 'total_shares_filed_excl_shoe',
    label: 'Total Shares Filed (excl shoe)',
  },
  underwriting_table_shares: {
    ...integerTableColumn,
    field: 'underwriting_table_shares',
    label: 'Total Shares',
    summaryConfig: [meanField, medianField, minField, maxField, sumField],
  },
  offering_primary_shares_base_offering: {
    ...integerTableColumn,
    field: 'offering_primary_shares_base_offering',
    label: 'Primary Shares Base Offering',
  },
  offering_secondary_shares_base_offering: {
    ...integerTableColumn,
    field: 'offering_secondary_shares_base_offering',
    label: 'Secondary Shares Base Offering',
  },
  filing_details_pct_secondary_shares: {
    ...percent1digitTableColumn,
    field: 'filing_details_pct_secondary_shares',
    label: '% Secondary',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_over_allotment_authorized: {
    ...integerTableColumn,
    field: 'offering_over_allotment_authorized',
    label: 'Over-allotment Authorized',
  },
  offering_over_allotment_exercised: {
    ...integerTableColumn,
    field: 'offering_over_allotment_exercised',
    label: 'Over-allotment Excercised',
  },
  filing_details_gross_proceeds: {
    field: 'filing_details_gross_proceeds',
    label: 'Gross Proceeds Base',
    summaryConfig: [meanField, medianField, minField, maxField, sumField],
    ...millionCurrencyTableColumn,
  },
  filing_details_gross_proceeds_with_over_allotment: {
    field: 'filing_details_gross_proceeds_with_over_allotment',
    label: 'Gross Proceeds Total',
    summaryConfig: [meanField, medianField, minField, maxField, sumField],
    ...millionCurrencyTableColumn,
  },
  filing_details_market_cap_at_pricing: {
    field: 'filing_details_market_cap_at_pricing',
    label: 'Post-Offering Mkt. Cap',
    summaryConfig: [meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  filing_details_market_cap_pre_offering: {
    field: 'filing_details_market_cap_pre_offering',
    label: 'Pre-Offering Mkt. Cap',
    summaryConfig: [meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  offering_post_offering_shares: {
    ...integerTableColumn,
    field: 'offering_post_offering_shares',
    label: 'Post Offering Shares Outstanding',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_pre_offering_shares: {
    ...integerTableColumn,
    field: 'offering_pre_offering_shares',
    label: 'Pre Offering Shares Outstanding',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  filing_details_deal_pct_at_pricing_market_cap: {
    ...percent1digitTableColumn,
    field: 'filing_details_deal_pct_at_pricing_market_cap',
    label: '% of Market Cap',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  filing_details_deal_pct_market_cap_pre_offering: {
    ...percent1digitTableColumn,
    field: 'filing_details_deal_pct_market_cap_pre_offering',
    label: '% of Pre-Offering Market Cap',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offerings_pre_offering_adtv: {
    ...integerTableColumn,
    field: 'offerings_pre_offering_adtv',
    label: 'ADTV',
  },
  offerings_size_as_multiple_of_adtv: {
    field: 'offerings_size_as_multiple_of_adtv',
    label: 'Mult. of ADTV',
    textAlign: 'right',
    summaryConfig: [meanField, medianField, minField, maxField],
    renderer: v => numericUtil.formatMultipleFactor(v),
  },
  offerings_file_to_offer_discount: {
    ...percent2digitTableColumn,
    field: 'offerings_file_to_offer_discount',
    label: 'File to Offer %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offerings_discount_to_last_trade: {
    ...percent2digitTableColumn,
    field: 'offerings_discount_to_last_trade',
    label: 'To Last Trade %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_discount_to_vwap: {
    ...percent2digitTableColumn,
    field: 'offering_discount_to_vwap',
    label: 'To VWAP %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offerings_discount_to_52wk_high: {
    ...percent2digitTableColumn,
    field: 'offerings_discount_to_52wk_high',
    label: 'To 52-Week High %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_gross_spread_pct: {
    ...percent2digitTableColumn,
    field: 'offering_gross_spread_pct',
    label: 'Gross Spread %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_gross_spread: {
    ...currencyTableColumn,
    field: 'offering_gross_spread',
    label: 'Gross Spread $',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offerings_all_in_cost: {
    ...percent2digitTableColumn,
    field: 'offerings_all_in_cost',
    label: 'All-in %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  estimated_fee: {
    field: 'estimated_fee',
    label: 'Estimated Fee',
    summaryConfig: [meanField, medianField, minField, maxField, sumField],
    ...millionCurrencyTableColumn,
  },
  offering_initial_registration_value: {
    field: 'offering_initial_registration_value',
    label: 'Initial Registration Value',
    summaryConfig: [meanField, medianField, minField, maxField, sumField],
    ...millionCurrencyTableColumn,
  },
  offering_initial_ipo_range_low: {
    ...currencyTableColumn,
    field: 'offering_initial_ipo_range_low',
    exportFields: ['offering_initial_ipo_range_low', 'offering_initial_ipo_range_high'],
    label: 'Initial IPO Range',
    renderer: (v, row) =>
      `${numericUtil.formatCurrency(
        row.offering_initial_ipo_range_low
      )} - ${numericUtil.formatCurrency(row.offering_initial_ipo_range_high)}`,
  },
  filing_details_price_vs_range: {
    field: 'filing_details_price_vs_range',
    label: 'Price vs. Range',
    filterType: FilterTypes.ENUM,
    filterEnum: createOptions(offeringPriceVsRangeLabels),
    renderer: value => offeringPriceVsRangeLabels[value],
  },
  offering_price_vs_midpoint: {
    ...percent1digitTableColumn,
    field: 'offering_price_vs_midpoint',
    label: '% to Midpoint',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_pct_change_in_size: {
    ...percent1digitTableColumn,
    field: 'offering_pct_change_in_size',
    label: '% Change In Size',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_revised_ipo_range_low: {
    ...currencyTableColumn,
    field: 'offering_revised_ipo_range_low',
    exportFields: ['offering_revised_ipo_range_low', 'offering_revised_ipo_range_high'],
    label: 'Revised IPO Range',
    renderer: (v, row) =>
      `${numericUtil.formatCurrency(
        row.offering_revised_ipo_range_low
      )} - ${numericUtil.formatCurrency(row.offering_revised_ipo_range_high)}`,
  },
  last_trade_before_filing: {
    ...currencyTableColumn,
    field: 'last_trade_before_filing',
    label: 'Last Trade Before Filing',
  },
  last_trade_before_offer: {
    ...currencyTableColumn,
    field: 'last_trade_before_offer',
    label: 'Last Trade Before Offer',
  },
  fifty_two_wk_high: {
    ...currencyTableColumn,
    field: 'fifty_two_wk_high',
    label: '52-Week High',
    summaryConfig: [meanField, medianField, minField, maxField, sumField],
  },
  re_offer_low: {
    ...currencyTableColumn,
    field: 're_offer_low',
    label: 'Re Offer Low',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  re_offer_high: {
    ...currencyTableColumn,
    field: 're_offer_high',
    label: 'Re Offer High',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  re_offer_discount_high: {
    ...percent2digitTableColumn,
    field: 're_offer_discount_high',
    label: 'Re Offer High %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  re_offer_discount_low: {
    ...percent2digitTableColumn,
    field: 're_offer_discount_low',
    label: 'Re Offer Low %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_first_day_volume: {
    ...integerTableColumn,
    field: 'offering_first_day_volume',
    label: 'First Day Volume',
  },
  offering_first_day_turnover: {
    field: 'offering_first_day_turnover',
    label: 'First Day Turnover',
    textAlign: 'right',
    summaryConfig: [meanField, medianField, minField, maxField],
    renderer: v => numericUtil.formatMultipleFactor(v),
  },
  offering_offer_to_open: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_open',
    label: 'Open',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_1day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_1day',
    label: '1 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_3day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_3day',
    label: '3 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_7day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_7day',
    label: '7 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_14day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_14day',
    label: '14 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_30day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_30day',
    label: '30 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_90day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_90day',
    label: '90 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_180day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_180day',
    label: '180 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_one_year: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_one_year',
    label: '1 Year',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_prior_quarter: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_prior_quarter',
    label: 'Prior Quarter',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_current: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_current',
    label: 'Current',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_total_managers: {
    ...integerTableColumn,
    field: 'offering_total_managers',
    label: '# of Managers',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_total_bookrunners: {
    ...integerTableColumn,
    field: 'offering_total_bookrunners',
    label: '# of Bookrunners',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_total_non_bookrunners: {
    ...integerTableColumn,
    field: 'offering_total_non_bookrunners',
    label: '# of Non-Bookrunners',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_total_pct_to_bookrunners: {
    ...percent1digitTableColumn,
    field: 'offering_total_pct_to_bookrunners',
    label: '% to Bookrunners',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_total_pct_to_non_bookrunners: {
    ...percent1digitTableColumn,
    field: 'offering_total_pct_to_non_bookrunners',
    label: '% to Non-Bookrunners',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_total_pct_to_left_lead: {
    ...percent1digitTableColumn,
    field: 'offering_total_pct_to_left_lead',
    label: '% to Left Lead',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  lock_up_period: {
    ...integerTableColumn,
    field: 'lock_up_period',
    label: 'Lock-up Period',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  lock_up_expiration: {
    ...dateTableColumn,
    field: 'lock_up_expiration',
    label: 'Lock-up Date',
  },
  lock_up_early_release_date: {
    ...dateTableColumn,
    field: 'lock_up_early_release_date',
    label: 'Lock-up Early Release Date',
  },
  left_lead_firm_name: {
    field: 'left_lead_firm_name',
    label: 'Left Lead',
    renderer: (value, row) => <LeftLeadFirmNameRenderer row={row} />,
  },
  left_lead: {
    field: 'left_lead',
    label: 'Left Lead',
  },
  filing_details_size_in_dollars: {
    field: 'filing_details_size_in_dollars',
    label: 'Offering Size',
    ...millionCurrencyTableColumn,
  },
  offering_preliminary_prospectus_link: {
    field: 'offering_preliminary_prospectus_link',
    label: 'Registration',
    renderer: (v, row) => {
      if (row.offering_preliminary_prospectus_link) {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${row.offering_preliminary_prospectus_link}`}
          >
            Registration
          </a>
        );
      }
      return '';
    },
  },
  underwriter_name: {
    field: 'underwriter_name',
    label: 'Underwriter',
  },
  book_offerings: {
    field: 'book_offerings',
    label: '# of Book',
    fixedMinWidth: 65,
    textAlign: 'right',
  },
  non_book_offerings: {
    field: 'non_book_offerings',
    label: '# of Non-Book',
    fixedMinWidth: 65,
    textAlign: 'right',
  },
  offerings: {
    field: 'offerings',
    label: 'Offerings',
    fixedMinWidth: 65,
    textAlign: 'right',
  },
  offerings_volume: {
    field: 'offerings_volume',
    label: 'Dollar Volume',
    ...millionCurrencyTableColumn,
  },
  underwritten_volume: {
    field: 'underwritten_volume',
    label: 'Underwritten $',
    ...millionCurrencyTableColumn,
  },
  book_fees: {
    field: 'book_fees',
    label: 'Book Fees',
    ...millionCurrencyTableColumn,
    textAlign: 'right',
  },
  non_book_fees: {
    field: 'non_book_fees',
    label: 'Non-Book Fees',
    ...millionCurrencyTableColumn,
  },
  min: {
    field: 'min',
    label: 'Min Fee',
    ...millionCurrencyTableColumn,
  },
  mean: {
    field: 'mean',
    label: 'Mean Fee',
    ...millionCurrencyTableColumn,
  },
  median: {
    field: 'median',
    label: 'Median Fee',
    ...millionCurrencyTableColumn,
  },
  max: {
    field: 'max',
    label: 'Max Fee',
    ...millionCurrencyTableColumn,
  },
  fees: {
    field: 'fees',
    label: 'Total Fees',
    ...millionCurrencyTableColumn,
  },
  wallet_share: {
    ...percent2digitTableColumn,
    field: 'wallet_share',
    label: 'Wallet Share',
  },
  follow_on_discount_from_filing: {
    ...percent2digitTableColumn,
    field: 'follow_on_discount_from_filing',
    label: 'File to Offer %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  follow_on_discount_to_last_trade: {
    ...percent2digitTableColumn,
    field: 'follow_on_discount_to_last_trade',
    label: 'To Last Trade %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  gross_spread: {
    ...percent2digitTableColumn,
    field: 'gross_spread',
    label: 'Gross Spread',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  pct_of_market_cap: {
    ...percent1digitTableColumn,
    field: 'pct_of_market_cap',
    label: '% of Market Cap',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  first_day_turnover: {
    field: 'first_day_turnover',
    label: 'First Day Turnover',
    textAlign: 'right',
    summaryConfig: [meanField, medianField, minField, maxField],
    renderer: v => numericUtil.formatMultipleFactor(v),
  },
  offer_to_open: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_open',
    label: 'Open',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offer_to_1day: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_1day',
    label: '1 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offer_to_3day: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_3day',
    label: '3 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offer_to_7day: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_7day',
    label: '7 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offer_to_14day: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_14day',
    label: '14 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offer_to_30day: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_30day',
    label: '30 Day',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offer_to_current: {
    ...percentPerformanceTableColumn,
    field: 'offer_to_current',
    label: 'Current',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_offer_to_vwap_1day: {
    ...percentPerformanceTableColumn,
    field: 'offering_offer_to_vwap_1day',
    label: '1 Day VWAP',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  allocation: {
    ...integerTableColumn,
    field: 'allocation',
    label: 'Allocation',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  ioi_shares: {
    ...integerTableColumn,
    field: 'ioi_shares',
    label: 'Indication',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  fill_rate: {
    ...percent2digitTableColumn,
    field: 'fill_rate',
    label: 'Fill Rate',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  allocation_pct: {
    ...percent2digitTableColumn,
    field: 'allocation_pct',
    label: '% of Deal',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  allocation_investment: {
    field: 'allocation_investment',
    label: 'Investment',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  underwriter_role: {
    field: 'underwriter_role',
    textAlign: 'left',
    label: 'Role',
  },
  economics_pct: {
    ...percent2digitTableColumn,
    field: 'economics_pct',
    label: 'Economics %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  sales_concession: {
    ...currencyTableColumn,
    field: 'sales_concession',
    label: 'Selling Concession',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
  },
  return_open_volume: {
    field: 'return_open_volume',
    label: '$ Return Open',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_open: {
    ...percentPerformanceTableColumn,
    field: 'return_open',
    label: '% Return Open',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  return_vwap_1day_volume: {
    field: 'return_vwap_1day_volume',
    label: '$ Return 1 Day VWAP',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_vwap_1day: {
    ...percentPerformanceTableColumn,
    field: 'return_vwap_1day',
    label: '% Return 1 Day VWAP',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  return_1day_volume: {
    field: 'return_1day_volume',
    label: '$ Return 1 Day',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_1day: {
    ...percentPerformanceTableColumn,
    field: 'return_1day',
    label: '% Return 1 Day',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  return_3day_volume: {
    field: 'return_3day_volume',
    label: '$ Return 3 Day',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_3day: {
    ...percentPerformanceTableColumn,
    field: 'return_3day',
    label: '% Return 3 Day',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  return_7day_volume: {
    field: 'return_7day_volume',
    label: '$ Return 7 Day',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_7day: {
    ...percentPerformanceTableColumn,
    field: 'return_7day',
    label: '% Return 7 Day',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  return_prior_quarter_volume: {
    field: 'return_prior_quarter_volume',
    label: '$ Return Prior Quarter',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_prior_quarter: {
    ...percentPerformanceTableColumn,
    field: 'return_prior_quarter',
    label: '% Return Prior Quarter',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  return_current_volume: {
    field: 'return_current_volume',
    label: '$ Return Current',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  return_current: {
    ...percentPerformanceTableColumn,
    field: 'return_current',
    label: '% Return Current',
    summaryConfig: [totalField, meanField, medianField, minField, maxField],
  },
  estimated_commission: {
    // todo check if it really needs to be 1 decimal point
    ...currencyTableColumn,
    field: 'estimated_commission',
    label: 'Selling Concessions',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    renderer: v => numericUtil.formatCurrency(v, 1),
  },
  sponsor_name: {
    field: 'sponsor_name',
    label: 'Sponsor',
    renderer: (v, row) => (
      <Link
        to={routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.MARKET_PULSE,
          type: 'table',
          query: {
            sponsor: row.id,
          },
        })}
      >
        {v}
      </Link>
    ),
  },
  total_fees: {
    field: 'total_fees',
    label: 'Total Fees',
    ...millionCurrencyTableColumn,
  },
  fund_name: {
    field: 'fund_name',
    label: 'Fund',
    renderer: (v, row) => (
      <Link
        to={routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.FUND_IOI_PARTICIPATION,
          type: 'table',
          query: {
            fund: row.id,
          },
        })}
      >
        {v}
      </Link>
    ),
  },
  ipos: {
    field: 'ipos',
    label: '# of IPOs',
    textAlign: 'right',
  },
  fos: {
    field: 'fos',
    label: '# of FOs',
    textAlign: 'right',
  },
  pct_of_firm_offerings: {
    ...percent2digitTableColumn,
    field: 'pct_of_firm_offerings',
    label: '% of Total Firm Offerings',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  pct_of_firm_total: {
    ...percent2digitTableColumn,
    field: 'pct_of_firm_total',
    label: '% of Total Firm Investment',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  pct_of_firm_allocation: {
    ...percent2digitTableColumn,
    field: 'pct_of_firm_allocation',
    label: 'Avg % Firm Allocation',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  pct_of_deal: {
    ...percent2digitTableColumn,
    field: 'pct_of_deal',
    label: 'Avg % of Deal',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  return_day7: {
    field: 'return_day7',
    label: 'Return Day 7',
    textAlign: 'right',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    renderer: v => <PerformanceCurrency value={v} />,
  },
  return_day3: {
    field: 'return_day3',
    label: 'Return Day 3',
    textAlign: 'right',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    renderer: v => <PerformanceCurrency value={v} />,
  },
  return_day1: {
    field: 'return_day1',
    label: 'Return Day 1',
    textAlign: 'right',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    renderer: v => <PerformanceCurrency value={v} />,
  },
  ord: {
    field: 'ord',
    label: 'Group',
    renderer: (v, row) => <SNoWrap>{row.ord_name}</SNoWrap>,
  },
  quantity: {
    ...integerTableColumn,
    field: 'quantity',
    label: 'Offerings',
    summaryConfig: [sumField, meanField, minField, maxField],
  },
  quantity_yoy: {
    ...percent1digitTableColumn,
    field: 'quantity_yoy',
    label: '% Change #',
    summaryConfig: [yoyField],
  },
  ipo_quantity: {
    ...integerTableColumn,
    field: 'ipo_quantity',
    label: '# IPOs',
    summaryConfig: [sumField, meanField, minField, maxField],
  },
  ipo_quantity_yoy: {
    ...percent1digitTableColumn,
    field: 'ipo_quantity_yoy',
    label: '% Change # IPOs',
    summaryConfig: [yoyField],
  },
  fo_quantity: {
    ...integerTableColumn,
    field: 'fo_quantity',
    label: '# FOs',
    summaryConfig: [sumField, meanField, minField, maxField],
  },
  fo_quantity_yoy: {
    ...percent1digitTableColumn,
    field: 'fo_quantity_yoy',
    label: '% Change # FOs',
    summaryConfig: [yoyField],
  },
  capital: {
    field: 'capital',
    label: 'Dollar Volume',
    summaryConfig: [sumField, meanField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  capital_yoy: {
    ...percent1digitTableColumn,
    field: 'capital_yoy',
    label: '% Change $',
    summaryConfig: [yoyField],
  },
  fee: {
    field: 'fee',
    label: 'Fees',
    summaryConfig: [sumField, meanField, minField, maxField],
    ...millionCurrencyTableColumn,
  },
  fee_yoy: {
    ...percent1digitTableColumn,
    field: 'fee_yoy',
    label: '% Change Fees',
    summaryConfig: [yoyField],
  },
  file_to_offer_discount: {
    ...percent1digitTableColumn,
    field: 'file_to_offer_discount',
    label: 'File to Offer %',
    summaryConfig: [meanField, minField, maxField],
  },
  primary_shareholder_name: {
    field: 'primary_shareholder_name',
    label: 'Shareholder',
    filterType: FilterTypes.STRING,
    renderer: (label: string, row: Row) => <ShareholderRenderer label={label} row={row} />,
  },
  offering_pre_offering_ownership: {
    ...integerTableColumn,
    field: 'offering_pre_offering_ownership',
    label: 'Pre Ownership Shares',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_pct_pre_offering_ownership: {
    ...percent2digitTableColumn,
    field: 'offering_pct_pre_offering_ownership',
    label: 'Pre Ownership %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_post_offering_ownership: {
    ...integerTableColumn,
    field: 'offering_post_offering_ownership',
    label: 'Post Ownership Shares',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_pct_post_offering_ownership: {
    ...percent2digitTableColumn,
    field: 'offering_pct_post_offering_ownership',
    label: 'Post Ownership %',
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  offering_use_of_proceeds: {
    field: 'offering_use_of_proceeds',
    label: 'Use of Proceeds',
  },
  offering_forward_agreement: {
    ...booleanTableColumn,
    field: 'offering_forward_agreement',
    label: 'Forward Sale',
  },
  first_follow_on: {
    ...nullableBooleanTableColumn,
    field: 'first_follow_on',
    label: 'First Follow-on',
  },
  split_adjusted_offering_price: {
    ...localCurrencyTableColumn,
    field: 'offering_split_adjusted_offering_price',
    label: 'Split Adjusted Offering Price',
  },
  conditional_lock_up: {
    ...nullableBooleanTableColumn,
    field: 'conditional_lock_up',
    label: 'Conditional Lock-up',
  },
  multiple_lock_ups: {
    ...nullableBooleanTableColumn,
    field: 'multiple_lock_ups',
    label: 'Multiple Lock-ups',
  },
  exchange_mic: {
    field: 'exchange_mic',
    label: 'Exchange',
    filterType: FilterTypes.ENUM,
    filterEnum: exchangeMicEnumFilterOptions,
    renderer: value => exchangeMicLabels[value]?.shortName || value,
  },
  country_code: {
    field: 'country_code',
    label: 'Exchange Country',
    renderer: value => {
      const country = Object.keys(countriesList).find(
        country => countriesList[country].countryCode === value
      );
      return country ? countriesList[country].countryDisplayName : value;
    },
  },
  pricing_instrument_region: {
    field: 'pricing_instrument_region',
    label: 'Exchange Region',
    renderer: value => value?.toUpperCase() || value,
  },
  pricing_currency_code: {
    field: 'pricing_currency_code',
    label: 'Currency',
    filterType: FilterTypes.ENUM,
    filterEnum: currencyEnumFilterOptions,
  },
  filing_details_offering_price_local_curr: {
    ...localCurrencyTableColumn,
    field: 'filing_details_offering_price_local_curr',
    label: 'Offer Price',
  },
  filing_details_gross_proceeds_local_curr: {
    ...millionLocalCurrencyTableColumn,
    field: 'filing_details_gross_proceeds_local_curr',
    label: 'Gross Proceed Base',
  },
  filing_details_gross_proceeds_with_over_allotment_local_curr: {
    ...millionLocalCurrencyTableColumn,
    field: 'filing_details_gross_proceeds_with_over_allotment_local_curr',
    label: 'Gross Proceed Total',
  },
  initial_gross_proceeds_base: {
    label: 'Gross Proceeds Base (Initial)',
    field: 'initial_gross_proceeds_base',
    ...millionCurrencyTableColumn,
  },
  initial_gross_proceeds_base_local_curr: {
    label: 'Gross Proceeds Base (Initial)',
    field: 'initial_gross_proceeds_base_local_curr',
    ...millionLocalCurrencyTableColumn,
  },
  revised_gross_proceeds_base: {
    label: 'Gross Proceeds Base (Revised)',
    field: 'revised_gross_proceeds_base',
    ...millionCurrencyTableColumn,
  },
  revised_gross_proceeds_base_local_curr: {
    label: 'Gross Proceeds Base (Revised)',
    field: 'revised_gross_proceeds_base_local_curr',
    ...millionLocalCurrencyTableColumn,
  },
  filing_details_market_cap_at_pricing_local_curr: {
    ...millionLocalCurrencyTableColumn,
    field: 'filing_details_market_cap_at_pricing_local_curr',
    label: 'Post-Offering Mkt. Cap',
  },
  offering_initial_ipo_range_low_local_curr: {
    ...localCurrencyTableColumn,
    field: 'offering_initial_ipo_range_low_local_curr',
    exportFields: [
      'offering_initial_ipo_range_low_local_curr',
      'offering_initial_ipo_range_high_local_curr',
    ],
    label: 'Initial IPO Range',
    renderer: (v, row) => {
      const low = row.offering_initial_ipo_range_low_local_curr;
      const high = row.offering_initial_ipo_range_high_local_curr;
      const pricingCurrencyCode = row.pricing_currency_code;
      if (low !== null && low >= 0 && high !== null && high > 0 && pricingCurrencyCode) {
        return (
          <React.Fragment>
            <CurrencyFormatter value={low} currency={pricingCurrencyCode} displayVariant="symbol" />
            {' - '}
            <CurrencyFormatter
              value={high}
              currency={pricingCurrencyCode}
              displayVariant="symbol"
            />
          </React.Fragment>
        );
      }
      return null;
    },
  },
  re_offer_low_local_curr: {
    ...localCurrencyTableColumn,
    field: 're_offer_low_local_curr',
    label: 'Re Offer Low',
  },
  re_offer_high_local_curr: {
    ...localCurrencyTableColumn,
    field: 're_offer_high_local_curr',
    label: 'Re Offer High',
  },
  last_trade_before_filing_local_curr: {
    ...localCurrencyTableColumn,
    field: 'last_trade_before_filing_local_curr',
    label: 'Last Trade Before Filing',
  },
  last_trade_before_offer_local_curr: {
    ...localCurrencyTableColumn,
    field: 'last_trade_before_offer_local_curr',
    label: 'Last Trade Before Offer',
  },
  carve_out: {
    ...nullableBooleanTableColumn,
    field: 'is_carve_out',
    label: 'Carve out',
  },
  clean_up_trade: {
    ...nullableBooleanTableColumn,
    field: 'is_clean_up_trade',
    label: 'Clean up trade',
  },
  concurrent_offering: {
    ...nullableBooleanTableColumn,
    field: 'is_concurrent_offering',
    label: 'Concurrent Offering',
  },
  cornerstone_investors: {
    ...nullableBooleanTableColumn,
    textAlign: 'left',
    field: 'has_cornerstone_investors',
    label: 'Cornerstone Investors (Y/N)',
  },
  cornerstone_investors_list: {
    field: 'cornerstone_investors_list',
    label: 'Cornerstone Investors',
    disableSort: true,
    textAlign: 'left',
    fixedMaxWidth: 300,
    renderer: (label: string, row: DLGWDataLabTableRowCompat) => <CornerstoneTooltip {...row} />,
  },
  cornerstone_investment: {
    ...millionLocalCurrencyTableColumn,
    field: 'cornerstone_total_amount',
    label: 'Cornerstone Investment Total',
  },
  cornerstone_investment_type: {
    textAlign: 'left',
    disableSort: true,
    field: 'cornerstone_investment_type',
    label: 'Cornerstone Investment Type',
    renderer: (label: string, row: DLGWDataLabTableRowCompat) => <CornerstoneType {...row} />,
  },
  number_of_cornerstone_investors: {
    field: 'cornerstone_investors_total_count',
    label: '# of Total Cornerstone Investors',
  },
  dual_listed: {
    ...nullableBooleanTableColumn,
    field: 'is_dual_listed',
    label: 'Additional Listing',
    headerRenderer: () => (
      <HeaderLabelRenderer
        label="Additional Listing"
        tooltip="Execution of an offering and simultaneous new exchange listing from an issuer with prior trade history."
      />
    ),
  },
  egc: {
    ...nullableBooleanTableColumn,
    field: 'egc',
    label: 'EGC',
  },
  synthetic_secondary: {
    ...nullableBooleanTableColumn,
    field: 'is_synthetic_secondary',
    label: 'Synthetic Secondary',
  },
  up_listing: {
    ...nullableBooleanTableColumn,
    field: 'is_up_listing',
    label: 'Up Listing',
  },
  use_of_proceeds_note: {
    field: 'use_of_proceeds_note',
    textAlign: 'left',
    label: 'Use of proceeds note',
    filterType: FilterTypes.STRING,
  },
  company_repurchase_included: {
    ...nullableBooleanTableColumn,
    field: 'is_company_repurchase_included',
    label: 'Company repurchase included',
  },
  company_repurchase_additional: {
    ...nullableBooleanTableColumn,
    field: 'is_company_repurchase_additional',
    label: 'Company repurchase additional',
  },
  company_repurchase: {
    label: 'Company Repurchase',
    field: 'is_company_repurchase_included',
    exportFields: ['is_company_repurchase_included', 'is_company_repurchase_additional'],
    disableSort: true,
    renderer: (_v, row) => {
      if (
        (row.is_company_repurchase_included === null ||
          row.is_company_repurchase_included === undefined) &&
        (row.is_company_repurchase_additional === null ||
          row.is_company_repurchase_additional === undefined)
      ) {
        return '-';
      }
      if (row.is_company_repurchase_included) {
        return 'Included';
      }
      if (row.is_company_repurchase_additional) {
        return 'Additional';
      }
      return 'No';
    },
  },
  headquarters: {
    label: 'Headquarters',
    field: 'headquarters',
    textAlign: 'left',
    filterType: FilterTypes.STRING,
  },
  naics: {
    label: 'Naics',
    field: 'naics',
    filterType: FilterTypes.NUMBER,
  },
};

/*
  List of overriding props for INTL columns
*/
export const intlOverridingProps = {
  [dtc.filing_details_gross_proceeds.field]: {
    label: 'Gross Proceeds Base $',
  },
  [dtc.filing_details_gross_proceeds_with_over_allotment.field]: {
    label: 'Gross Proceeds Total $',
  },
  [dtc.filing_details_market_cap_at_pricing.field]: {
    label: 'Post-Offering Mkt. Cap $',
  },
  [dtc.filing_details_offering_price.field]: {
    label: 'Offer Price $',
  },
  [dtc.last_trade_before_filing.field]: {
    label: 'Last Trade Before Filing $',
  },
  [dtc.last_trade_before_offer.field]: {
    label: 'Last Trade Before Offer $',
  },
  [dtc.offering_initial_ipo_range_low.field]: {
    label: 'Initial IPO Range $',
  },
  [dtc.re_offer_high.field]: {
    label: 'Re Offer High $',
  },
  [dtc.re_offer_low.field]: {
    label: 'Re Offer Low $',
  },
  [dtc.initial_gross_proceeds_base.field]: {
    label: 'Gross Proceeds Base $ (Initial)',
  },
  [dtc.revised_gross_proceeds_base.field]: {
    label: 'Gross Proceeds Base $ (Revised)',
  },
};

/*
  List of common columns used almost on every datalab screen

  All of these are FILTERABLE by default.
  For excluding from advanced filter, put the column into excludeFromFilters list below
*/
export const masterColumns: DatalabTableColumn[] = [
  dtc.offering_confidential_filing_date,
  dtc.offering_public_filing_date,
  dtc.offering_launch_date,
  dtc.timing_of_launch,
  dtc.offerings_pricing_date,
  dtc.offering_first_trade_date,
  dtc.offering_settlement_date,
  dtc.companies_issuer,
  dtc.companies_ticker,
  dtc.companies_cik,
  dtc.companies_cusip,
  dtc.issuer_isin,
  dtc.offering_entity_structure,
  dtc.exchange_mic,
  dtc.pricing_instrument_region,
  dtc.country_code,
  dtc.offerings_sector,
  dtc.companies_sub_sector,
  { ...dtc.offerings_type, label: 'Type' },
  dtc.offering_security_type,
  dtc.pricing_currency_code,
  dtc.filing_details_offering_price_local_curr,
  dtc.filing_details_offering_price,
  dtc.filing_details_net_price,
  dtc.split_adjusted_offering_price,
  dtc.offering_size,
  dtc.total_shares_filed_excl_shoe,
  dtc.offering_primary_shares_base_offering,
  dtc.offering_secondary_shares_base_offering,
  dtc.filing_details_pct_secondary_shares,
  dtc.offering_over_allotment_authorized,
  dtc.offering_over_allotment_exercised,
  dtc.filing_details_gross_proceeds_local_curr,
  dtc.filing_details_gross_proceeds,
  dtc.filing_details_gross_proceeds_with_over_allotment_local_curr,
  dtc.filing_details_gross_proceeds_with_over_allotment,
  dtc.initial_gross_proceeds_base,
  dtc.initial_gross_proceeds_base_local_curr,
  dtc.revised_gross_proceeds_base,
  dtc.revised_gross_proceeds_base_local_curr,
  dtc.filing_details_market_cap_at_pricing_local_curr,
  dtc.filing_details_market_cap_at_pricing,
  dtc.filing_details_market_cap_pre_offering,
  dtc.offering_post_offering_shares,
  dtc.offering_pre_offering_shares,
  dtc.filing_details_deal_pct_at_pricing_market_cap,
  dtc.filing_details_deal_pct_market_cap_pre_offering,
  dtc.offerings_pre_offering_adtv,
  dtc.offerings_size_as_multiple_of_adtv,
  dtc.offerings_file_to_offer_discount,
  dtc.offerings_discount_to_last_trade,
  dtc.offering_discount_to_vwap,
  dtc.offerings_discount_to_52wk_high,
  dtc.offering_gross_spread_pct,
  dtc.offering_gross_spread,
  dtc.offerings_all_in_cost,
  dtc.estimated_fee,
  dtc.offering_initial_registration_value,
  dtc.offering_initial_ipo_range_low_local_curr,
  dtc.offering_initial_ipo_range_low,
  dtc.filing_details_price_vs_range,
  dtc.offering_price_vs_midpoint,
  dtc.offering_pct_change_in_size,
  dtc.offering_revised_ipo_range_low,
  dtc.last_trade_before_filing_local_curr,
  dtc.last_trade_before_filing,
  dtc.last_trade_before_offer_local_curr,
  dtc.last_trade_before_offer,
  dtc.fifty_two_wk_high,
  dtc.re_offer_low_local_curr,
  dtc.re_offer_low,
  dtc.re_offer_high_local_curr,
  dtc.re_offer_high,
  dtc.re_offer_discount_high,
  dtc.re_offer_discount_low,
  dtc.offering_first_day_volume,
  dtc.offering_first_day_turnover,
  dtc.offering_offer_to_open,
  dtc.offering_offer_to_vwap_1day,
  dtc.offering_offer_to_1day,
  dtc.offering_offer_to_3day,
  dtc.offering_offer_to_7day,
  dtc.offering_offer_to_14day,
  dtc.offering_offer_to_30day,
  dtc.offering_offer_to_90day,
  dtc.offering_offer_to_180day,
  dtc.offering_offer_to_one_year,
  dtc.offering_offer_to_prior_quarter,
  dtc.offering_offer_to_current,
  dtc.offering_total_managers,
  dtc.offering_total_bookrunners,
  dtc.offering_total_non_bookrunners,
  dtc.offering_total_pct_to_bookrunners,
  dtc.offering_total_pct_to_non_bookrunners,
  dtc.offering_total_pct_to_left_lead,
  dtc.lock_up_period,
  dtc.lock_up_expiration,
  dtc.lock_up_early_release_date,
  dtc.conditional_lock_up,
  dtc.multiple_lock_ups,
  dtc.left_lead_firm_name,
  dtc.primary_shareholder_name,
  dtc.offering_pre_offering_ownership,
  dtc.offering_pct_pre_offering_ownership,
  dtc.offering_post_offering_ownership,
  dtc.offering_pct_post_offering_ownership,
  dtc.offering_use_of_proceeds,
  dtc.offering_forward_agreement,
  dtc.first_follow_on,
  dtc.carve_out,
  dtc.clean_up_trade,
  dtc.concurrent_offering,
  dtc.dual_listed,
  dtc.egc,
  dtc.synthetic_secondary,
  dtc.up_listing,
  dtc.use_of_proceeds_note,
  dtc.company_repurchase_additional,
  dtc.company_repurchase,
  dtc.headquarters,
  dtc.naics,
  dtc.cornerstone_investors,
  dtc.number_of_cornerstone_investors,
  dtc.cornerstone_investors_list,
  dtc.cornerstone_investment,
  dtc.cornerstone_investment_type,
];

export const localCurrencyColumns: DatalabTableColumn[] = [
  dtc.exchange_mic,
  dtc.pricing_instrument_region,
  dtc.country_code,
  dtc.pricing_currency_code,
  dtc.filing_details_gross_proceeds_local_curr,
  dtc.filing_details_gross_proceeds_with_over_allotment_local_curr,
  dtc.filing_details_market_cap_at_pricing_local_curr,
  dtc.filing_details_offering_price_local_curr,
  dtc.last_trade_before_filing_local_curr,
  dtc.last_trade_before_offer_local_curr,
  dtc.offering_initial_ipo_range_low_local_curr,
  dtc.re_offer_high_local_curr,
  dtc.re_offer_low_local_curr,
  dtc.initial_gross_proceeds_base_local_curr,
  dtc.revised_gross_proceeds_base_local_curr,
];

export const visibleLocalCurrFields = [
  dtc.exchange_mic.field,
  dtc.pricing_instrument_region.field,
  dtc.country_code.field,
  dtc.pricing_currency_code.field,
  dtc.filing_details_gross_proceeds_local_curr.field,
  dtc.filing_details_gross_proceeds_with_over_allotment_local_curr.field,
  dtc.filing_details_market_cap_at_pricing_local_curr.field,
  dtc.filing_details_offering_price_local_curr.field,
  dtc.last_trade_before_filing_local_curr.field,
  dtc.last_trade_before_offer_local_curr.field,
  dtc.offering_initial_ipo_range_low_local_curr.field,
  dtc.re_offer_high_local_curr.field,
  dtc.re_offer_low_local_curr.field,
  dtc.initial_gross_proceeds_base_local_curr.field,
  dtc.revised_gross_proceeds_base_local_curr.field,
];

export const screensWithoutIntlData = [
  DatalabScreens.PL_LEAGUE_TABLE,
  DatalabScreens.LEAGUE_TABLE,
  DatalabScreens.SPONSOR_LEAGUE_TABLE,
  DatalabScreens.IOI_LEAGUE_TABLE,
  DatalabScreens.AFTERMARKET_MATRIX,
  DatalabScreens.UNDERWRITER_PROFILE_SELL,
  DatalabScreens.UNDERWRITING_TERMS,
];

export const showInternationalDataOnScreen = (screen: DatalabScreens): boolean =>
  !screensWithoutIntlData.includes(screen);
