import { DateRangePresetTypes, ToastManager } from '@cmg/common';
import saveAs from 'file-saver';
import chunk from 'lodash/chunk';
import merge from 'lodash/merge';
import memoize from 'memoize-one';
import { combineReducers } from 'redux';
import { hide, show } from 'redux-modal';
import { SagaIterator } from 'redux-saga';
import { all, call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import api from '../../api/dl-ruby/datalab-api';
import {
  CreateReportResponse,
  createUserReport,
  deleteUserReport,
  FavoriteReportResponse,
  favoriteUserReport,
  fetchAdvisorOptions,
  fetchFunds,
  fetchManagerOptions,
  FetchManagerOptionsResponse,
  fetchUserReport,
  listUserReports,
  searchSponsorOptions,
  ShareReportResponse,
  shareUserReport,
  UpdateReportResponse,
  updateUserReport,
} from '../../api/dlgw';
import { browserHistory } from '../../common/redux/browser-history';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  REQUEST,
  SUCCESS,
} from '../../common/redux/reduxHelpers';
import { RootState } from '../../common/redux/rootReducer';
import routeFactory from '../../common/util/routeFactory';
import { OldApiResponse } from '../../types/api/ApiResponse';
import { Pagination } from '../../types/api/pagination';
import { SponsorOption } from '../../types/domain/firm/sponsorOptions';
import {
  UserReport,
  UserReportCreate,
  UserReportShare,
  UserReportUpdate,
} from '../../types/domain/report/userReport';
import {
  UserReportPartial,
  UserReportPartialsFetch,
} from '../../types/domain/report/userReportPartial';
import { User } from '../../types/domain/user/user';
import { toastMessages } from '../shared/constants/messages';
import { selectUserSettings } from '../shared/ducks';
import { createOptionFromFund } from '../shared/model/utils';
import { ALL_REPORTS_MODAL_ID } from './components/modals/AllReportsModal';
import { REPORT_DETAILS_MODAL_ID } from './components/modals/ReportDetailsModal';
import { DatalabScreens, DatalabScreenViewTypes, ReportDetailsModalMode } from './constants';
import { DownloadReportProps } from './containers/DatalabContainer';
import { isInTable } from './model/datalab.model';
import {
  createDatalabRequestDto,
  decorateDatalabFilterValues,
  getPayload,
} from './model/datalab-api-mapping';
import {
  getFilterDefaultValues,
  getFilterSettings,
  getNextFilterValues,
  mapDefaultValues,
} from './model/filters.model';
import { getReportFilterValues } from './model/reports.model';
import { getTableConfig, TableConfigType } from './model/table.model';
import { DatalabFilterValues, DatalabState } from './types';

/**
 * ACTION TYPES
 */
const FETCH_ADVISORY_OPTIONS = 'new-datalab/FETCH_ADVISORY_OPTIONS';
const FETCH_FUND_OPTIONS = 'new-datalab/FETCH_FUND_OPTIONS';
const FETCH_UNDERWRITER_OPTIONS = 'new-datalab/FETCH_UNDERWRITER_OPTIONS';
const FETCH_DATALAB_CHART = 'new-datalab/FETCH_DATALAB_CHART';
const FETCH_REPORT_SELECT_DEFAULT_OPTIONS = 'new-datalab/FETCH_REPORT_SELECT_DEFAULT_OPTIONS';
const FETCH_REPORT_SELECT_DEFAULT_OPTIONS_SUCCESS =
  'new-datalab/FETCH_REPORT_SELECT_DEFAULT_OPTIONS_SUCCESS';
const FETCH_REPORT_SELECT_DEFAULT_OPTIONS_REQUEST =
  'new-datalab/FETCH_REPORT_SELECT_DEFAULT_OPTIONS_REQUEST';
const FETCH_REPORT_SELECT_DEFAULT_OPTIONS_FAILURE =
  'new-datalab/FETCH_REPORT_SELECT_DEFAULT_OPTIONS_FAILURE';
const FETCH_REPORT_OPTIONS = 'new-datalab/FETCH_REPORT_OPTIONS';
const LOAD_REPORT = 'new-datalab/LOAD_REPORT';
const CREATE_REPORT = 'new-datalab/CREATE_REPORT';
const CREATE_REPORT_SUCCESS = 'new-datalab/CREATE_REPORT_SUCCESS';
const CREATE_REPORT_FAILURE = 'new-datalab/CREATE_REPORT_FAILURE';
const UPDATE_REPORT = 'new-datalab/UPDATE_REPORT';
const DELETE_REPORT = 'new-datalab/DELETE_REPORT';
const SHARE_REPORT = 'new-datalab/SHARE_REPORT';
const FAVORITE_REPORT = 'new-datalab/FAVORITE_REPORT';
const INIT_DATALAB = 'new-datalab/INIT_DATALAB';
const RELOAD_DATALAB = 'new-datalab/RELOAD_DATALAB';
const INIT_REPORT_DETAILS_MODAL = 'new-datalab/INIT_REPORT_DETAILS_MODAL';
const CREATE_REPORT_CONFIRMED = 'new-datalab/CREATE_REPORT_CONFIRMED';
const CREATE_REPORT_CANCELLED = 'new-datalab/CREATE_REPORT_CANCELLED';

export enum ActionTypes {
  DATALAB_DOWNLOAD = 'new-datalab/DATALAB_DOWNLOAD',
  RE_INIT_DATALAB = 'new-datalab/RE_INIT_DATALAB',
  SET_VISIBLE_COLUMNS = 'new-datalab/SET_VISIBLE_COLUMNS',
  SET_DEFAULT_VALUES = 'new-datalab/SET_DEFAULT_VALUES',
  LOAD_REPORT = 'new-datalab/LOAD_REPORT',
  FETCH_DATALAB_DATA = 'new-datalab/FETCH_DATALAB_DATA',
  FETCH_SPONSOR_OPTIONS_REQUEST = 'new-datalab/FETCH_SPONSOR_OPTIONS_REQUEST',
  FETCH_SPONSOR_OPTIONS_SUCCESS = 'new-datalab/FETCH_SPONSOR_OPTIONS_SUCCESS',
  RESET_REPORT = 'new-datalab/RESET_REPORT',
  FETCH_DATALAB_TABLE_SUCCESS = 'new-datalab/FETCH_DATALAB_TABLE_SUCCESS',
  FETCH_ORGANIZATION_USERS_REQUEST = 'FETCH_ORGANIZATION_USERS_REQUEST',
}

/**
 * ACTION CREATORS
 */
export const downloadDatalabTable = (
  params: {
    section: DatalabScreens;
  } & DownloadReportProps
) => ({
  type: ActionTypes.DATALAB_DOWNLOAD,
  payload: params,
});

export const reInitDatalab = (params: {
  prevScreen: DatalabScreens;
  nextScreen: DatalabScreens;
  prevScreenType: DatalabScreenViewTypes;
  nextScreenType: DatalabScreenViewTypes;
  nextUrlQuery: { [key: string]: string };
}) => ({
  type: ActionTypes.RE_INIT_DATALAB,
  payload: params,
});

export const setVisibleColumns = (params: { visibleColumns: string[] }) => ({
  type: ActionTypes.SET_VISIBLE_COLUMNS,
  payload: params,
});

export const setDefaultValues = (
  params: Partial<{
    filters: DatalabFilterValues;
    filterDefaultValues: DatalabFilterValues;
    page: number;
    perPage: number;
    orderBy: string | null;
    orderByDirection: string | null;
    visibleColumns: string[];
  }>
) => ({
  type: ActionTypes.SET_DEFAULT_VALUES,
  payload: params,
});

export const loadReport = (params: { reportId: string }) => ({
  type: ActionTypes.LOAD_REPORT,
  payload: params,
});

export const fetchDatalabData = (params: {
  screen: DatalabScreens;
  screenType: DatalabScreenViewTypes;
  filters: DatalabFilterValues;
  page: number;
  perPage: number;
  orderBy: string | null;
  orderByDirection: string | null;
}) => ({
  type: ActionTypes.FETCH_DATALAB_DATA,
  payload: params,
});

export const fetchSponsorOptionsRequest = (params: { sponsorIds: string[] }) => ({
  type: ActionTypes.FETCH_SPONSOR_OPTIONS_REQUEST,
  payload: params,
});

export const fetchSponsorOptionsSuccess = (params: { rows: SponsorOption[] }) => ({
  type: ActionTypes.FETCH_SPONSOR_OPTIONS_SUCCESS,
  payload: params,
});

export const resetReport = () => ({
  type: ActionTypes.RESET_REPORT,
});

export const fetchDatalabTableSuccess = (params: {
  section: DatalabScreens;
  data: {
    table_rows: any[];
    table_summaries: {
      [key: string]: any;
    };
    pagination?: Pagination;
  };
}) => ({
  type: ActionTypes.FETCH_DATALAB_TABLE_SUCCESS,
  payload: params,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.DATALAB_DOWNLOAD]: ReturnType<typeof downloadDatalabTable>;
  [ActionTypes.RE_INIT_DATALAB]: ReturnType<typeof reInitDatalab>;
  [ActionTypes.SET_VISIBLE_COLUMNS]: ReturnType<typeof setVisibleColumns>;
  [ActionTypes.SET_DEFAULT_VALUES]: ReturnType<typeof setDefaultValues>;
  [ActionTypes.LOAD_REPORT]: ReturnType<typeof loadReport>;
  [ActionTypes.FETCH_DATALAB_DATA]: ReturnType<typeof fetchDatalabData>;
  [ActionTypes.FETCH_SPONSOR_OPTIONS_REQUEST]: ReturnType<typeof fetchSponsorOptionsRequest>;
  [ActionTypes.FETCH_SPONSOR_OPTIONS_SUCCESS]: ReturnType<typeof fetchSponsorOptionsSuccess>;
  [ActionTypes.RESET_REPORT]: ReturnType<typeof resetReport>;
  [ActionTypes.FETCH_DATALAB_TABLE_SUCCESS]: ReturnType<typeof fetchDatalabTableSuccess>;
};
export const fetchAdvisoryOptionsActions = createApiActionCreators(FETCH_ADVISORY_OPTIONS);
export const fetchFundOptionsActions = createApiActionCreators(FETCH_FUND_OPTIONS);
export const fetchUnderwriterOptionsActions = createApiActionCreators(FETCH_UNDERWRITER_OPTIONS);
export const fetchReportSelectDefaultOptionsActions = createApiActionCreators(
  FETCH_REPORT_SELECT_DEFAULT_OPTIONS
);
export const fetchUserReportPartialsActions = createApiActionCreators(FETCH_REPORT_OPTIONS);
export const fetchDatalabChartActions = createActionCreator(FETCH_DATALAB_CHART);
export const loadReportActions = createApiActionCreators(LOAD_REPORT);
export const createReportActions = createApiActionCreators(CREATE_REPORT);
export const updateReportActions = createApiActionCreators(UPDATE_REPORT);
export const deleteReportActions = createApiActionCreators(DELETE_REPORT);
export const shareReportActions = createApiActionCreators(SHARE_REPORT);
export const favoriteReportActions = createApiActionCreators(FAVORITE_REPORT);
export const initDatalabAction = createActionCreator(INIT_DATALAB);
export const reloadDatalabAction = createActionCreator(RELOAD_DATALAB);
export const initReportDetailsModalActions = createApiActionCreators(INIT_REPORT_DETAILS_MODAL);
export const createUserReportConfirmed = createActionCreator(CREATE_REPORT_CONFIRMED);
export const createUserReportCancelled = createActionCreator(CREATE_REPORT_CANCELLED);

/**
 * REDUCERS
 */

const thisYear = new Date().getFullYear();
const prevYear = thisYear - 1;

export const initialState: DatalabState = {
  visibleColumns: [],
  advisoryOptions: [],
  fundOptions: [],
  sponsorOptions: [],
  underwriterOptions: [],
  datalabCharts: {
    ipoStructure: {
      historical_offering_volume: [],
      days_from_confidential_filing_to_public_filing: [],
      offering_volume_by_sector: [],
      historical_deal_size_market_cap_trends: [],
      size_pre_post_offering_market_cap: [],
      percent_ipos_with_secondary_component: [],
      percent_secondary_shares_offered: [],
    },
    ipoPricing: {
      pricing_vs_initial_range: undefined,
      offer_to_midpoint_percent_change: undefined,
      percent_of_ipos_trading_above_offer: [],
      performance_heat_map_by_sector: { items: [], min_value: 0, max_value: 0 },
      after_market_relative_to_pricing: {
        price_vs_midpoint: {
          offer_to_open: { points: [], line: [] },
          offer_to_day: { points: [], line: [] },
          offer_to_month: { points: [], line: [] },
          offer_to_three_months: { points: [], line: [] },
          offer_to_six_months: { points: [], line: [] },
          offer_to_current: { points: [], line: [] },
        },
        deal_pct_at_pricing_market_cap: {
          offer_to_open: { points: [], line: [] },
          offer_to_day: { points: [], line: [] },
          offer_to_month: { points: [], line: [] },
          offer_to_three_months: { points: [], line: [] },
          offer_to_six_months: { points: [], line: [] },
          offer_to_current: { points: [], line: [] },
        },
      },
      first_day_turnover: [],
      volume_dropoff_by_sector: undefined,
    },
    followOnStructure: {
      historical_offering_volume: undefined,
      offering_volume_by_sector: undefined,
      size_pre_post_offering_market_cap: undefined,
      size_as_a_mult_of_adtv: undefined,
    },
    followOnPricing: {
      mean_discount_to_last_trade_by_offering_type: [],
      median_discount_to_last_trade_by_offering_type: [],
      file_to_offer_discount: undefined,
      discount_to_last_trade: undefined,
      all_in_cost: undefined,
      performance_heat_map_by_sector: { items: [], min_value: 0, max_value: 0 },
      performance_heat_map_by_offering_type: {
        items: [],
        min_value: 0,
        max_value: 0,
      },
      first_day_turnover: [],
      first_day_volume_divided_by_deal_shares: undefined,
      after_market_relative_to_sizing: {
        market_cap: {
          offer_to_open: { points: [], line: [] },
          offer_to_day: { points: [], line: [] },
          offer_to_month: { points: [], line: [] },
          offer_to_three_months: { points: [], line: [] },
          offer_to_six_months: { points: [], line: [] },
          offer_to_current: { points: [], line: [] },
        },
        adtv: {
          offer_to_open: { points: [], line: [] },
          offer_to_day: { points: [], line: [] },
          offer_to_month: { points: [], line: [] },
          offer_to_three_months: { points: [], line: [] },
          offer_to_six_months: { points: [], line: [] },
          offer_to_current: { points: [], line: [] },
        },
      },
      after_market_relative_to_pricing: {
        file_to_offer_discount: {
          offer_to_open: { points: [], line: [] },
          offer_to_day: { points: [], line: [] },
          offer_to_month: { points: [], line: [] },
          offer_to_three_months: { points: [], line: [] },
          offer_to_six_months: { points: [], line: [] },
          offer_to_current: { points: [], line: [] },
        },
        discount_to_last_trade: {
          offer_to_open: { points: [], line: [] },
          offer_to_day: { points: [], line: [] },
          offer_to_month: { points: [], line: [] },
          offer_to_three_months: { points: [], line: [] },
          offer_to_six_months: { points: [], line: [] },
          offer_to_current: { points: [], line: [] },
        },
      },
    },
    underwritingTerms: {
      gross_spread: undefined,
      all_in_cost: undefined,
      nr_of_managers: undefined,
      nr_of_bookrunners: undefined,
      nr_of_non_book_managers: undefined,
      total_percent_to_bookrunners: undefined,
      percent_to_left_lead: undefined,
      total_percent_of_non_books: undefined,
    },
    underwriterProfileSellSide: {
      historical_offering_volume_by_type: [],
      sector_composition: [],
      fees_by_type: undefined,
      fees_by_sector: undefined,
      performance_by_sector: [],
      performance_heat_map_by_offering_type: {
        items: [],
        min_value: 0,
        max_value: 0,
      },
      average_economics_role: undefined,
      average_economics_sector: undefined,
      average_fee_role_sector: {
        TOTAL_FEES: [],
        TOTAL_OFFERINGS: [],
        AVERAGE_FEE: [],
      },
    },
    underwriterProfileBuySide: {
      historical_offering_volume: [],
      sector_composition: undefined,
      performance_by_sector: undefined,
      performance_offering_type_size: undefined,
    },
    marketPulse: {
      offerings: 0,
      capital_raised: 0,
      ipo: {
        below: 0,
        within: 0,
        above: 0,
        day1: 0,
        day30: 0,
        current: 0,
      },
      follow_on: {
        file_to_offer: 0,
        to_last_trade: 0,
        day1: 0,
        day30: 0,
        current: 0,
      },
      offering_by_type_info: {
        ipo: {
          quantity: 0,
          price: 0,
        },
        followon: {
          quantity: 0,
          price: 0,
        },
      },
      offering_volume_by_type: undefined,
      offering_volume_by_sector: undefined,
      historical_offering_volume: undefined,
      performance_heat_map_by_sector: { items: [], min_value: 0, max_value: 0 },
      performance_heat_map_by_offering_type: {
        items: [],
        min_value: 0,
        max_value: 0,
      },
      percent_of_offerings_trading_above_offer: [],
      first_day_turnover: [],
    },
    ioiParticipation: {
      offerings: 0,
      invested_dollars: 0,
      ipo: {
        below: 0,
        within: 0,
        above: 0,
        day1: 0,
        day30: 0,
        current: 0,
      },
      follow_on: {
        file_to_offer: 0,
        to_last_trade: 0,
        day1: 0,
        day30: 0,
        current: 0,
      },
      offering_by_type_info: {
        ipo: {
          quantity: 0,
          price: 0,
        },
        followon: {
          quantity: 0,
          price: 0,
        },
      },
      participation_volume_by_type: undefined,
      participation_volume_by_sector: undefined,
      historical_participation_volume: undefined,
      performance_heat_map_by_sector: { items: [], min_value: 0, max_value: 0 },
      weighted_performance_heat_map_by_sector: {
        items: [],
        min_value: 0,
        max_value: 0,
      },
      after_market_relative_to_ioi: {
        pct_fill: {
          offer_to_open: { line: [], points: [] },
          offer_to_day: { line: [], points: [] },
          offer_to_month: { line: [], points: [] },
          offer_to_three_months: { line: [], points: [] },
          offer_to_six_months: { line: [], points: [] },
          offer_to_current: { line: [], points: [] },
        },
        pct_of_deal: {
          offer_to_open: { line: [], points: [] },
          offer_to_day: { line: [], points: [] },
          offer_to_month: { line: [], points: [] },
          offer_to_three_months: { line: [], points: [] },
          offer_to_six_months: { line: [], points: [] },
          offer_to_current: { line: [], points: [] },
        },
      },
      percent_of_offerings_trading_above_offer: [],
      first_day_turnover: [],
    },
    capitalRaisedMatrix: {
      weekly: {
        [`${prevYear}`]: { rows: [], summaries: {} },
        [`${thisYear}`]: { rows: [], summaries: {} },
      },
      monthly: {
        [`${prevYear}`]: { rows: [], summaries: {} },
        [`${thisYear}`]: { rows: [], summaries: {} },
      },
      quarterly: {
        [`${prevYear}`]: { rows: [], summaries: {} },
        [`${thisYear}`]: { rows: [], summaries: {} },
      },
    },
  },
  datalabTable: {},
  defaultReportSelectOptions: {
    isLoading: true,
    latest: {
      options: [],
      pagination: null,
    },
    favorites: {
      options: [],
      pagination: null,
    },
  },
  reportDetailsModal: {
    error: null,
  },
  userReportPartials: {
    options: [],
    pagination: null,
  },
  report: null,
  filters: {},
  filterDefaultValues: {},
  page: 1,
  perPage: 25,
  orderBy: null,
  orderByDirection: null,
};

const visibleColumnsReducer = createReducer(initialState.visibleColumns, {
  [ActionTypes.SET_VISIBLE_COLUMNS]: (
    state: DatalabState['visibleColumns'],
    payload: Actions[ActionTypes.SET_VISIBLE_COLUMNS]['payload']
  ) => payload.visibleColumns || state,
  [ActionTypes.SET_DEFAULT_VALUES]: (
    state: DatalabState['visibleColumns'],
    payload: Actions[ActionTypes.SET_DEFAULT_VALUES]['payload']
  ) => payload.visibleColumns || state,
});

const filtersReducer = createReducer(initialState.filters, {
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => payload.filters || state,
});

const filterDefaultValuesReducer = createReducer(initialState.filterDefaultValues, {
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => payload.filterDefaultValues || state,
});

const pageReducer = createReducer(initialState.page, {
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => payload.page || state,
});

const perPageReducer = createReducer(initialState.perPage, {
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => payload.perPage || state,
});

const orderByReducer = createReducer(initialState.orderBy, {
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => payload.orderBy || state,
});

const orderByDirectionReducer = createReducer(initialState.orderByDirection, {
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => payload.orderByDirection || state,
});

const advisoryOptionsReducer = createReducer(initialState.advisoryOptions, {
  [FETCH_ADVISORY_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload,
  },
});

const fundOptionsReducer = createReducer(initialState.fundOptions, {
  [FETCH_FUND_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload,
  },
});

const sponsorOptionsReducer = createReducer(initialState.sponsorOptions, {
  [ActionTypes.FETCH_SPONSOR_OPTIONS_SUCCESS]: (state, { rows }: { rows: SponsorOption[] }) => rows,
});

const underwriterOptionsReducer = createReducer(initialState.underwriterOptions, {
  [FETCH_UNDERWRITER_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload,
  },
});

const datalabChartsReducer = createReducer(initialState.datalabCharts, {
  [FETCH_DATALAB_CHART]: (state, { section, data }) => ({
    ...state,
    ipoStructure: section === DatalabScreens.IPO_STRUCTURE ? data : state.ipoStructure,
    ipoPricing: section === DatalabScreens.IPO_PRICING ? data : state.ipoPricing,
    followOnStructure:
      section === DatalabScreens.FOLLOW_ON_STRUCTURE ? data : state.followOnStructure,
    followOnPricing: section === DatalabScreens.FOLLOW_ON_PRICING ? data : state.followOnPricing,
    underwritingTerms:
      section === DatalabScreens.UNDERWRITING_TERMS ? data : state.underwritingTerms,
    underwriterProfileSellSide:
      section === DatalabScreens.UNDERWRITER_PROFILE_SELL ? data : state.underwriterProfileSellSide,
    underwriterProfileBuySide:
      section === DatalabScreens.UNDERWRITER_PROFILE_BUY ? data : state.underwriterProfileBuySide,
    marketPulse: section === DatalabScreens.MARKET_PULSE ? data : state.marketPulse,
    ioiParticipation: section === DatalabScreens.IOI_PARTICIPATION ? data : state.ioiParticipation,
    capitalRaisedMatrix:
      section === DatalabScreens.CAPITAL_RAISED_MATRIX ? data : state.capitalRaisedMatrix,
  }),
});

const datalabTableReducer = createReducer(initialState.datalabTable, {
  [ActionTypes.FETCH_DATALAB_TABLE_SUCCESS]: (state, { section, data }) => ({
    ...state,
    [section]: data,
  }),
});

const reportDetailsModalErrorReducer = createReducer(initialState.reportDetailsModal.error, {
  [CREATE_REPORT_SUCCESS]: () => null,
  [CREATE_REPORT_FAILURE]: (state, payload) => payload,
  [CREATE_REPORT_CANCELLED]: () => null,
});

const reportDetailsModalReducer = combineReducers({
  error: reportDetailsModalErrorReducer,
});

const userReportPartialsListReducer = createReducer(initialState.userReportPartials.options, {
  [FETCH_REPORT_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.options,
  },
  [SHARE_REPORT]: {
    [SUCCESS]: (state, payload) =>
      state !== undefined
        ? state.map((report: UserReportPartial) =>
            report.id === payload.id
              ? { ...report, share: payload.share, lastModified: payload.lastModified }
              : report
          )
        : state,
  },
  [FAVORITE_REPORT]: {
    [SUCCESS]: (state, payload) =>
      state !== undefined
        ? state.map((report: UserReportPartial) =>
            report.id === payload.id ? { ...report, isFavorite: payload.isFavorite } : report
          )
        : state,
  },
  [UPDATE_REPORT]: {
    [SUCCESS]: (state, payload) =>
      state.map((report: UserReportPartial) =>
        report.id === payload.id ? { ...report, id: payload.id, name: payload.name } : report
      ),
  },
});

const userReportPartialsPaginationReducer = createReducer(
  initialState.userReportPartials.pagination,
  {
    [FETCH_REPORT_OPTIONS]: {
      [SUCCESS]: (state, payload) => payload.pagination,
    },
  }
);

const userReportPartialsReducer = combineReducers({
  options: userReportPartialsListReducer,
  pagination: userReportPartialsPaginationReducer,
});

const latestReportSelectOptionsReducer = createReducer(
  initialState.defaultReportSelectOptions.latest,
  {
    [FETCH_REPORT_SELECT_DEFAULT_OPTIONS_SUCCESS]: (state, payload) => payload.latest,
  }
);

const favoriteReportSelectOptionsReducer = createReducer(
  initialState.defaultReportSelectOptions.favorites,
  {
    [FETCH_REPORT_SELECT_DEFAULT_OPTIONS_SUCCESS]: (state, payload) => payload.favorites,
  }
);

const reportSelectOptionsIsLoadingReducer = createReducer(
  initialState.defaultReportSelectOptions.isLoading,
  {
    [FETCH_REPORT_SELECT_DEFAULT_OPTIONS_SUCCESS]: () => false,
    [FETCH_REPORT_SELECT_DEFAULT_OPTIONS_FAILURE]: () => false,
    [FETCH_REPORT_SELECT_DEFAULT_OPTIONS_REQUEST]: () => true,
  }
);

const defaultReportSelectOptionsReducer = combineReducers({
  isLoading: reportSelectOptionsIsLoadingReducer,
  latest: latestReportSelectOptionsReducer,
  favorites: favoriteReportSelectOptionsReducer,
});

const reportReducer = createReducer(initialState.report, {
  [LOAD_REPORT]: {
    [SUCCESS]: (state, payload) => payload,
  },
  [CREATE_REPORT]: {
    [SUCCESS]: (state, payload) => payload,
  },
  [SHARE_REPORT]: {
    [SUCCESS]: (state, payload) =>
      state && state.id === payload.id ? { ...state, share: payload.share } : state,
  },
  [FAVORITE_REPORT]: {
    [SUCCESS]: (state, payload) =>
      state && state.id === payload.id ? { ...state, isFavorite: payload.isFavorite } : state,
  },
  [UPDATE_REPORT]: {
    [SUCCESS]: (state, payload) => payload,
  },
  [ActionTypes.SET_DEFAULT_VALUES]: (state, payload) => {
    if (state) {
      const { useCustomSectors: prevUseCustomSectors } = state.filters.dataLab;
      const { useCustomSectors } = payload.filters;

      // If custom sectors was toggled, clear the current report from
      // state.  In this scenario, we want to prevent users from saving
      // an existing report with custom sectors because the report may
      // be shared with users who do not have custom sectors enabled.
      if (prevUseCustomSectors !== useCustomSectors) {
        return initialState.report;
      }
    }
    return state;
  },
  [ActionTypes.RE_INIT_DATALAB]: (state, payload) => {
    const { nextScreen, prevScreen } = payload;
    return nextScreen === prevScreen ? state : initialState.report;
  },
  [ActionTypes.RESET_REPORT]: () => initialState.report,
});

export default combineReducers({
  visibleColumns: visibleColumnsReducer,
  advisoryOptions: advisoryOptionsReducer,
  fundOptions: fundOptionsReducer,
  sponsorOptions: sponsorOptionsReducer,
  underwriterOptions: underwriterOptionsReducer,
  datalabCharts: datalabChartsReducer,
  datalabTable: datalabTableReducer,
  defaultReportSelectOptions: defaultReportSelectOptionsReducer,
  reportDetailsModal: reportDetailsModalReducer,
  userReportPartials: userReportPartialsReducer,
  report: reportReducer,
  filters: filtersReducer,
  filterDefaultValues: filterDefaultValuesReducer,
  page: pageReducer,
  perPage: perPageReducer,
  orderBy: orderByReducer,
  orderByDirection: orderByDirectionReducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState) => state.datalab;
export const selectVisibleColumns = state => selectState(state).visibleColumns;
export const selectAdvisoryOptions = state => selectState(state).advisoryOptions;
export const selectFundOptions = state => selectState(state).fundOptions;
export const selectSponsorOptions = state => selectState(state).sponsorOptions;
export const selectUnderwriterOptions = state => selectState(state).underwriterOptions;
const selectCharts = state => selectState(state).datalabCharts;
export const selectIpoStructureCharts = state => selectCharts(state).ipoStructure;
export const selectIpoPricingCharts = state => selectCharts(state).ipoPricing;
export const selectFollowOnStructureCharts = state => selectCharts(state).followOnStructure;
export const selectFollowOnPricingCharts = state => selectCharts(state).followOnPricing;
export const selectUnderwritingTermsCharts = state => selectCharts(state).underwritingTerms;
export const selectUnderwriterProfileBuySideCharts = state =>
  selectCharts(state).underwriterProfileBuySide;
export const selectUnderwriterProfileSellSideCharts = state =>
  selectCharts(state).underwriterProfileSellSide;
export const selectMarketPulseCharts = state => selectCharts(state).marketPulse;
export const selectIoiParticipationCharts = state => selectCharts(state).ioiParticipation;
export const selectCapitalRaisedMatrixCharts = state => selectCharts(state).capitalRaisedMatrix;
export const selectDatalabTable = createSelector(
  (state: RootState) => selectState(state).datalabTable,
  datalabTable =>
    memoize(
      section =>
        datalabTable[section] || {
          table_rows: [],
          table_summaries: {},
          pagination: {
            activePage: 1,
            hasNext: false,
            hasPrevious: false,
            perPage: 25,
            totalCount: 0,
            totalPages: 1,
          },
        }
    )
);
export const selectReport = (state): DatalabState['report'] => selectState(state).report;
export const selectReportDetailsModal = state => selectState(state).reportDetailsModal;
export const selectDefaultReportSelectOptionsLoading = memoize(
  state => selectState(state).defaultReportSelectOptions.isLoading
);
export const selectDefaultReportSelectOptions = memoize(
  (
    state
  ): {
    name: string;
    options: UserReportPartial[];
  }[] => {
    const { favorites, latest } = selectState(state).defaultReportSelectOptions;

    return [
      ...(latest.options.length > 0
        ? [
            {
              name: `Latest`,
              options: latest.options,
            },
          ]
        : []),
      ...(favorites.options.length > 0
        ? [
            {
              name: `Favorites`,
              options: favorites.options,
            },
          ]
        : []),
    ];
  }
);
export const selectActiveFilterCount = (state, section?: DatalabScreens) => {
  const filters = selectFilters(state);
  const filterFormValues = mapDefaultValues(filters);

  // These properties exist in the filters object, but should not be
  // included when calculating the active filter count.
  const ignoredFormFields = ['groupId', 'date', 'useCustomSectors', 'filterForAllocations'];

  // Ignore this filter from count if we are hiding the region/country filter from certain sections,
  // but set the filter to default to ['USA']
  section && !getFilterSettings(section).showInternational && ignoredFormFields.push('countries');

  return Object.keys(filterFormValues)
    .filter(filterFieldKey => !ignoredFormFields.includes(filterFieldKey))
    .reduce((acc, currentFilterFieldKey) => {
      const filterField = filterFormValues[currentFilterFieldKey];
      if (filterField === undefined || filterField === null) {
        // The filter field value is undefined or null - do not increment
        // the active filter count.
        return acc;
      } else if (filterField.rules && filterField.rules.length > 0) {
        // Calculation behavior for the advanced query object
        // - increment the active filter count for every custom/advanced query.
        return acc + filterField.rules.length;
      } else if (Array.isArray(filterField)) {
        // Calculation behavior for array fields - if the array
        // length is greater than 1, increment the active filter count by 1.
        return acc + (filterField.length > 0 ? 1 : 0);
      } else if (
        // Calculation behavior for range fields - if the min or max values
        // are greater than 1, increment the active filter count by 1.
        (filterField.min !== null &&
          filterField.min !== undefined &&
          Number(filterField.min) >= 0) ||
        (filterField.max !== null && filterField.max !== undefined && Number(filterField.max) >= 0)
      ) {
        return acc + 1;
      } else if (typeof filterField === 'boolean') {
        // Calculation behavior for boolean fields - if the min or max values
        // are greater than 1, increment the active filter count by 1.
        return acc + 1;
      }

      return acc;
    }, 0);
};

export const selectUserReportPartials = state => ({
  options: selectState(state).userReportPartials.options,
  pagination: selectState(state).userReportPartials.pagination,
});
export const selectFilters = state => selectState(state).filters;
export const selectFilterDefaultValues = state => selectState(state).filterDefaultValues;
export const selectPage = state => selectState(state).page;
export const selectPerPage = state => selectState(state).perPage;
const selectOrderBy = state => selectState(state).orderBy;
const selectOrderByDirection = state => selectState(state).orderByDirection;
/**
 * Gets the default table configuration for a particular datalab screen and overrides some properties
 * with duck state if they have been set.
 * */
export const selectTableConfig = createSelector(
  [(_, datalabScreen: string) => datalabScreen, selectOrderBy, selectOrderByDirection],
  (datalabScreen, orderBy, orderByDirection): TableConfigType => {
    const defaultTableConfig = getTableConfig(datalabScreen);
    return {
      ...defaultTableConfig,
      orderBy: orderBy || defaultTableConfig.orderBy,
      orderByType: orderByDirection || defaultTableConfig.orderByType,
    };
  }
);
export const selectTableQuickFilter = (state, section) => {
  const filters = selectFilters(state);

  switch (section) {
    case DatalabScreens.SPONSOR_LEAGUE_TABLE:
    case DatalabScreens.AFTERMARKET_MATRIX:
      return filters.avgMethod;
    case DatalabScreens.PL_LEAGUE_TABLE:
    case DatalabScreens.BROKER_PL_OFFERINGS:
      return filters.plCredit;
    default:
      return null;
  }
};

/**
 * SAGAS
 */
export function* initDatalabSaga({ payload }) {
  const { section, sectionType, query: urlQuery } = payload;

  const state = yield select();
  const userSettings = selectUserSettings(state);

  const tableConfig = getTableConfig(section);
  const page = initialState.page;
  const perPage = initialState.perPage;
  const orderBy = tableConfig.orderBy;
  const orderByDirection = tableConfig.orderByType;
  const filters = getFilterDefaultValues(urlQuery, section, userSettings);
  const visibleColumns = tableConfig.builtVisibleColumns || [];

  yield put(resetReport());

  yield put(
    setDefaultValues({
      // todo init visible columns
      filters,
      filterDefaultValues: filters,
      page,
      perPage,
      orderBy,
      orderByDirection,
      visibleColumns,
    })
  );

  yield fetchDatalabSaga(section, sectionType, filters, page, perPage, orderBy, orderByDirection);
  yield call(
    // @ts-ignore TS not correctly understanding overloaded push
    browserHistory.push,
    routeFactory.datalab.getUrlPath({
      screen: section,
      type: sectionType,
      query: urlQuery,
    })
  );
  yield put(fetchReportSelectDefaultOptionsActions.request());
}

function* reInitDatalabSaga({ payload }: Actions[ActionTypes.RE_INIT_DATALAB]) {
  const { prevScreen, nextScreen, prevScreenType, nextScreenType, nextUrlQuery } = payload;

  const state: RootState = yield select();
  const currentFilterDefaultValues = selectFilterDefaultValues(state);
  const currentFilterValues = selectFilters(state);
  const userSettings = selectUserSettings(state);

  const tableConfig = getTableConfig(nextScreen);
  const page = initialState.page;
  const perPage = initialState.perPage;
  const orderBy = tableConfig.orderBy;
  const orderByDirection = tableConfig.orderByType;
  const visibleColumns =
    prevScreen === nextScreen ? selectVisibleColumns(state) : tableConfig.builtVisibleColumns;
  const { nextFilters, nextDefaultFilters } = getNextFilterValues(
    prevScreen,
    nextScreen,
    prevScreenType,
    nextScreenType,
    nextUrlQuery,
    currentFilterDefaultValues,
    currentFilterValues,
    userSettings
  );

  yield put(
    setDefaultValues({
      filters: nextFilters,
      filterDefaultValues: nextDefaultFilters,
      page,
      perPage,
      orderBy,
      orderByDirection,
      visibleColumns,
    })
  );

  yield fetchDatalabSaga(
    nextScreen,
    nextScreenType,
    nextFilters,
    page,
    perPage,
    orderBy,
    orderByDirection
  );
}

function* reloadDatalabSaga({ payload }) {
  const { section, sectionType } = payload;

  const state = yield select();

  const page = payload.page || selectPage(state);
  const perPage = payload.perPage || selectPerPage(state);
  const orderBy = payload.orderBy || selectOrderBy(state);
  const orderByDirection = payload.orderByDirection || selectOrderByDirection(state);
  const filters = payload.filters || selectFilters(state);
  const filterDefaultValues = payload.filters ? payload.filters : selectFilterDefaultValues(state);
  const visibleColumns = selectVisibleColumns(state);

  yield put(
    setDefaultValues({
      filters,
      filterDefaultValues,
      page,
      perPage,
      orderBy,
      orderByDirection,
      visibleColumns,
    })
  );

  yield fetchDatalabSaga(section, sectionType, filters, page, perPage, orderBy, orderByDirection);
}

export function* fetchDatalabSaga(
  section,
  sectionType,
  filters,
  page,
  perPage,
  orderBy,
  orderByDirection
) {
  const query = getPayload(
    section,
    sectionType,
    filters,
    getFilterSettings(section),
    page,
    perPage,
    orderBy,
    orderByDirection
  );
  const request = createDatalabRequestDto(query);

  if (isInTable(section, sectionType)) {
    const resp = yield call(api.fetchDatalabTable, section, request);
    if (resp.ok) {
      // todo fix api, should be just resp.data.data
      const data = typeof resp.data === 'string' ? JSON.parse(resp.data) : resp.data;
      yield put(fetchDatalabTableSuccess({ section: section, data: data }));
    }
  } else {
    const resp = yield call(api.fetchDatalab, section, request);
    if (resp.ok) {
      // todo fix api, should be just resp.data.data
      const data = typeof resp.data === 'string' ? JSON.parse(resp.data) : resp.data;
      yield put(fetchDatalabChartActions({ section: section, data: data }));
    }
  }
}

export function* downloadDatalabTableSaga({ payload }: Actions[ActionTypes.DATALAB_DOWNLOAD]) {
  const {
    section,
    columns,
    includeOfferingNotes,
    includeIoiNotes,
    includeUnderwriters,
    includeSponsors,
    includeFundIoi,
    includeAdvisors,
  } = payload;

  const state: RootState = yield select();
  const filters = selectFilters(state);
  const filterSettings = getFilterSettings(section);

  const query = {
    datalab: decorateDatalabFilterValues(section, filters, filterSettings),
    columns,
    includeOfferingNotes,
    includeIoiNotes,
    includeUnderwriters,
    includeSponsors,
    includeFundIoi,
    includeAdvisors,
    useCustomSectors: filters.useCustomSectors,
  };
  const request = createDatalabRequestDto(query);
  const resp = yield call(api.downloadDatalabTable, section, request);

  if (resp.ok) {
    const disposition = resp.headers['content-disposition'];
    const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);

    // todo fix api, should be just resp.data.data
    saveAs(resp.data, filename);
  }
}

export function* fetchAdvisoryOptionsSaga() {
  const resp = yield call(fetchAdvisorOptions);

  if (resp.ok) {
    // todo fix api, should be just resp.data.data
    yield put(fetchAdvisoryOptionsActions.success(resp.data));
  }
}

export function* fetchFundOptionsSaga() {
  const resp = yield call(fetchFunds);

  if (resp.ok) {
    const options = resp.data.map(createOptionFromFund);
    yield put(fetchFundOptionsActions.success(options));
  }
}

export function* fetchSponsorOptionsSaga({
  payload,
}: Actions[ActionTypes.FETCH_SPONSOR_OPTIONS_REQUEST]) {
  // Get sponsors in parallel requests to avoid too long url issues
  const chunkedIds = chunk(payload.sponsorIds, 20);
  const requests = chunkedIds.map(ids =>
    call(searchSponsorOptions, { sponsorIds: ids, perPage: ids.length, page: 1 })
  );

  const responses = yield all(requests);

  if (responses.every(({ ok }) => ok)) {
    // Flat map fetch options from each request
    const rows = responses.reduce((acc, { data }) => [...acc, ...data.data], []);

    yield put(fetchSponsorOptionsSuccess({ rows }));
  }
}

export function* fetchUnderwriterOptionsSaga() {
  const resp: FetchManagerOptionsResponse = yield call(fetchManagerOptions);

  if (resp.ok) {
    // todo fix api, should be just resp.data.data
    yield put(fetchUnderwriterOptionsActions.success(resp.data));
  }
}

export function* fetchReportSelectDefaultOptionsSaga() {
  const favoritedUserReportPartialsResponse = yield call(listUserReports, {
    favorites: true,
    paginationParams: {
      page: 1,
      perPage: 100,
    },
  });

  const latestUserReportPartialsResponse = yield call(listUserReports, {
    paginationParams: {
      orderField: 'last_activity',
      orderDirection: 'desc',
      page: 1,
      perPage: 5,
    },
  });

  if (favoritedUserReportPartialsResponse.ok && latestUserReportPartialsResponse.ok) {
    const payload = {
      latest: {
        options: latestUserReportPartialsResponse.data.data,
        pagination: latestUserReportPartialsResponse.data.pagination,
      },
      favorites: {
        options: favoritedUserReportPartialsResponse.data.data,
        pagination: favoritedUserReportPartialsResponse.data.pagination,
      },
    };
    yield put(fetchReportSelectDefaultOptionsActions.success(payload));
  }
}

export function* fetchUserReportPartialsSaga({ payload }: { payload: UserReportPartialsFetch }) {
  const resp = yield call(listUserReports, payload);

  if (resp.ok) {
    const { data: options, pagination } = resp.data;
    yield put(fetchUserReportPartialsActions.success({ options, pagination }));
  }
}

export function* fetchReportSaga({ payload }: Actions[ActionTypes.LOAD_REPORT]) {
  yield put(hide(ALL_REPORTS_MODAL_ID));

  const { reportId } = payload;

  const resp = yield call(fetchUserReport, reportId);

  if (resp.ok) {
    const userReport: UserReport = merge(resp.data, {
      // When the report time window is set to custom,
      // the custom start date and end date values are stored in
      // the filters object.  When the time window is set to
      // a rolling date, the start and end dates are dynamically
      // set via the backend and provided in the root of the report
      // object.
      filters: {
        dataLab: {
          startDateValue:
            resp.data.timeWindow === DateRangePresetTypes.CUSTOM
              ? resp.data.filters.dataLab.startDateValue
              : resp.data.startDate,
          endDateValue:
            resp.data.timeWindow === DateRangePresetTypes.CUSTOM
              ? resp.data.filters.dataLab.endDateValue
              : resp.data.endDate,
          // useCustomSectors isn't stored within report.filters,
          // but rather on the report itself - report.useCustomSectors
          // this includes it within dataLab filters
          useCustomSectors: resp.data.useCustomSectors,
        },
      },
    });

    yield put(loadReportActions.success(userReport));

    const screen = userReport.datalabPage;
    const screenType = userReport.isTableView
      ? DatalabScreenViewTypes.TABLE
      : DatalabScreenViewTypes.CHART;
    const query = { report: userReport.id };

    const tableConfig = getTableConfig(screen);
    const page = initialState.page;
    const perPage = initialState.perPage;
    const orderBy = tableConfig.orderBy;
    const orderByDirection = tableConfig.orderByType;
    const filters = getReportFilterValues(userReport);
    const visibleColumns =
      userReport.fields === undefined || userReport.fields.length === 0
        ? tableConfig.builtVisibleColumns
        : userReport.fields;

    yield put(
      setDefaultValues({
        filters,
        filterDefaultValues: filters,
        page,
        perPage,
        orderBy,
        orderByDirection,
        visibleColumns,
      })
    );

    yield call(
      // @ts-ignore TS not correctly understanding overloaded push
      browserHistory.push,
      routeFactory.datalab.getUrlPath({ screen, type: screenType, query })
    );

    yield put(
      fetchDatalabData({ screen, screenType, filters, page, perPage, orderBy, orderByDirection })
    );

    yield put(fetchReportSelectDefaultOptionsActions.request());

    const sponsorIds = userReport.filters.dataLab.sponsorsValues;

    if (sponsorIds && sponsorIds.length) {
      yield put(fetchSponsorOptionsRequest({ sponsorIds }));
    }
  }
}

export function* shareReportSaga({
  payload: { reportId, shareUserIds, showToast },
}: {
  payload: { reportId: UserReport['id']; shareUserIds: User['id'][]; showToast?: boolean };
}): SagaIterator {
  yield put(hide(REPORT_DETAILS_MODAL_ID));

  const data: UserReportShare = {
    shareUserIds: shareUserIds,
  };
  const resp: ShareReportResponse = yield call(shareUserReport, reportId, data);

  if (resp.ok) {
    yield put(shareReportActions.success(resp.data));

    if (showToast) {
      ToastManager.success(toastMessages.success.updatedReportSharingSettings);
    }

    yield put(fetchReportSelectDefaultOptionsActions.request());
  }
}

export function* favoriteReportSaga({
  payload: { reportId, isFavorite, showToast },
}: {
  payload: { reportId: UserReport['id']; isFavorite: boolean; showToast?: boolean };
}): SagaIterator {
  const resp: FavoriteReportResponse = yield call(favoriteUserReport, reportId, isFavorite);

  if (resp.ok) {
    yield put(favoriteReportActions.success(resp.data));

    if (showToast) {
      ToastManager.success(
        isFavorite ? toastMessages.success.reportFavorited : toastMessages.success.reportUnfavorited
      );
    }

    yield put(fetchReportSelectDefaultOptionsActions.request());
  }
}

export function* createReportSaga({ payload }) {
  const { section, sectionType, name, shareUserIds } = payload;
  const state: RootState = yield select();
  const filters = selectFilters(state);
  const visibleColumns = selectVisibleColumns(state);

  const data: UserReportCreate = {
    reportName: name,
    timeWindow: filters.typeDateValue || DateRangePresetTypes.CUSTOM,
    datalabType: section,
    isTableView: sectionType === DatalabScreenViewTypes.TABLE,
    filters: { dataLab: filters },
    fields: visibleColumns,
  };

  const resp: OldApiResponse<CreateReportResponse> = yield call(createUserReport, data);

  if (resp.ok) {
    yield put(hide(REPORT_DETAILS_MODAL_ID));

    yield put(createReportActions.success(resp.data));
    ToastManager.success(toastMessages.success.createdReport);

    // @ts-ignore response from API does not follow required structure: { data: CreateReportResponse }
    const reportId = resp.data.id;
    if (shareUserIds) {
      yield shareReportSaga({
        payload: {
          reportId,
          shareUserIds: shareUserIds,
        },
      });
    }

    yield fetchReportSaga({ type: ActionTypes.LOAD_REPORT, payload: { reportId } });
  } else {
    ToastManager.error('Failed to create a report');
  }
}

export function* initReportDetailsModal({ payload }) {
  const { reportDetailsModalMode, section, sectionType } = payload;

  yield put(show(REPORT_DETAILS_MODAL_ID, { reportDetailsModalMode, section, sectionType }));

  yield race({
    confirmed: take(CREATE_REPORT_CONFIRMED),
    cancelled: take(CREATE_REPORT_CANCELLED),
  });
}

export function* confirmReportDetailsModalSaga({ payload }) {
  const { reportDetailsModalMode } = payload;

  if (reportDetailsModalMode === ReportDetailsModalMode.CREATE) {
    const { section, sectionType, values } = payload;
    const { name, shareUserIds } = values;
    yield put(createReportActions.request({ section, sectionType, name, shareUserIds }));
  } else if (reportDetailsModalMode === ReportDetailsModalMode.EDIT) {
    const {
      values: { reportId, shareUserIds },
    } = payload;
    yield put(shareReportActions.request({ reportId, shareUserIds, showToast: true }));
  }
}

function* cancelReportDetailsModalSaga() {
  yield put(hide(REPORT_DETAILS_MODAL_ID));
}

export function* updateReportSaga({
  payload: { report, section, sectionType },
}: {
  payload: { report: UserReport; section: DatalabScreens; sectionType: DatalabScreenViewTypes };
}) {
  const state: RootState = yield select();

  const visibleColumns = selectVisibleColumns(state);
  const dataLabFilters = selectFilters(state);
  const data: UserReportUpdate = {
    reportName: report.name,
    timeWindow: dataLabFilters.typeDateValue!,
    datalabType: section,
    isTableView: sectionType === DatalabScreenViewTypes.TABLE,
    filters: { dataLab: dataLabFilters },
    fields: visibleColumns,
  };

  const resp: UpdateReportResponse = yield call(updateUserReport, report.id, data);

  if (resp.ok) {
    // Server changes some of the IDs when update is performed.
    // We have to reflect those changes in our filter selection to avoid false positive diffing.
    const filters = getReportFilterValues(resp.data);
    yield put(setDefaultValues({ filters }));

    yield put(updateReportActions.success(resp.data));
    ToastManager.success(toastMessages.success.updatedReport);
  }

  yield put(fetchReportSelectDefaultOptionsActions.request());
}

export function* deleteReportSaga({ payload }) {
  const { reportId, paginationParams } = payload;

  const resp = yield call(deleteUserReport, reportId);

  if (resp.ok) {
    const state: RootState = yield select();
    const report = selectReport(state);
    const userReportPartials = selectUserReportPartials(state);

    // If the deleted report exists in the current page
    // of report options, refetch the existing page.
    if (
      userReportPartials.options.find(report => report.id === reportId) &&
      userReportPartials.pagination
    ) {
      const { activePage, perPage, totalPages } = userReportPartials.pagination;
      const { orderField, orderDirection } = paginationParams;

      // If the last item in the last page is deleted,
      // load the preceding page of report options -
      // otherwise reload the current active page.
      const page =
        activePage > 1 && activePage === totalPages && userReportPartials.options.length === 1
          ? activePage - 1
          : activePage;
      const newPaginationParams = { page, perPage, orderField, orderDirection };
      yield put(fetchUserReportPartialsActions.request({ paginationParams: newPaginationParams }));
    }

    // If the deleted report is the report currently being
    // viewed, reset all report settings, filters, etc.
    if (report && report.id === reportId) {
      const { isTableView, datalabPage: section } = report;
      const sectionType = isTableView ? DatalabScreenViewTypes.TABLE : DatalabScreenViewTypes.CHART;
      yield initDatalabSaga({ payload: { section, sectionType, query: {} } });
    } else {
      yield put(fetchReportSelectDefaultOptionsActions.request());
    }

    yield put(deleteReportActions.success({ reportId }));
  }
}

// temporal - to convert fetchDatalabSaga into a saga callable only via redux action
function* fetchDatalabDataSaga({ payload }: Actions[ActionTypes.FETCH_DATALAB_DATA]) {
  const { screen, screenType, filters, page, perPage, orderBy, orderByDirection } = payload;

  yield fetchDatalabSaga(screen, screenType, filters, page, perPage, orderBy, orderByDirection);
}

export function* datalabSaga() {
  // @ts-ignore
  yield takeLatest(INIT_DATALAB, initDatalabSaga);
  // @ts-ignore
  yield takeLatest(RELOAD_DATALAB, reloadDatalabSaga);
  yield takeLatest(createActionType(FETCH_ADVISORY_OPTIONS, REQUEST), fetchAdvisoryOptionsSaga);
  yield takeLatest(createActionType(FETCH_FUND_OPTIONS, REQUEST), fetchFundOptionsSaga);
  yield takeLatest(ActionTypes.FETCH_SPONSOR_OPTIONS_REQUEST, fetchSponsorOptionsSaga);
  yield takeLatest(
    createActionType(FETCH_UNDERWRITER_OPTIONS, REQUEST),
    fetchUnderwriterOptionsSaga
  );
  // @ts-ignore
  yield takeLatest(createActionType(FETCH_REPORT_OPTIONS, REQUEST), fetchUserReportPartialsSaga);
  // @ts-ignore
  yield takeLatest(createActionType(LOAD_REPORT, REQUEST), fetchReportSaga);
  // @ts-ignore
  yield takeLatest(createActionType(INIT_REPORT_DETAILS_MODAL, REQUEST), initReportDetailsModal);
  // @ts-ignore
  yield takeLatest(createActionType(CREATE_REPORT_CONFIRMED), confirmReportDetailsModalSaga);
  yield takeLatest(createActionType(CREATE_REPORT_CANCELLED), cancelReportDetailsModalSaga);
  // @ts-ignore
  yield takeLatest(createActionType(CREATE_REPORT, REQUEST), createReportSaga);
  yield takeLatest(
    createActionType(FETCH_REPORT_SELECT_DEFAULT_OPTIONS, REQUEST),
    fetchReportSelectDefaultOptionsSaga
  );
  // @ts-ignore
  yield takeLatest(createActionType(UPDATE_REPORT, REQUEST), updateReportSaga);
  // @ts-ignore
  yield takeLatest(createActionType(DELETE_REPORT, REQUEST), deleteReportSaga);
  // @ts-ignore
  yield takeLatest(createActionType(SHARE_REPORT, REQUEST), shareReportSaga);
  // @ts-ignore
  yield takeLatest(createActionType(FAVORITE_REPORT, REQUEST), favoriteReportSaga);

  yield takeEvery<Actions[ActionTypes.DATALAB_DOWNLOAD]>(
    ActionTypes.DATALAB_DOWNLOAD,
    downloadDatalabTableSaga
  );

  yield takeEvery<Actions[ActionTypes.RE_INIT_DATALAB]>(
    ActionTypes.RE_INIT_DATALAB,
    reInitDatalabSaga
  );

  yield takeEvery<Actions[ActionTypes.FETCH_DATALAB_DATA]>(
    ActionTypes.FETCH_DATALAB_DATA,
    fetchDatalabDataSaga
  );
}
