import { apiUtil } from '@cmg/common';
import axios, { AxiosResponse } from 'axios';

import { getAppSettings } from '../../config/appSettings';
import { ApiResponse } from '../../types/api/ApiResponse';
import { UUID } from '../../types/common';
import { UnderwriterCreditsAllocation } from '../../types/domain/underwriter-credits/underwriterCreditsAllocation';
import { UnderwriterCreditsManager } from '../../types/domain/underwriter-credits/underwriterCreditsManager';
import { UnderwriterCreditsOffering } from '../../types/domain/underwriter-credits/underwriterCreditsOffering';
import { DATALAB_API_CALL_ID, downloadReport, fetchReportChart, fetchReportTable } from '../dlgw';
import { snakeCaseToCamelCaseAxiosConfig } from '../dlgw/compat/snakeCaseToCamelCaseAxiosConfig';
import { isMigrated } from './ruby-migration-roll-out';

/**
 * @deprecated Use datalabGatewayApi or GraphQL instead. We are transitioning away from this ruby hosted api.
 */
export const rubyApiClient = axios.create({
  responseType: 'json',
  headers: {
    'Client-App-Version': getAppSettings().client.appVersion,
    'Content-Type': 'application/json',
  },
  ...snakeCaseToCamelCaseAxiosConfig,
});

const datalab = {
  fetchChart: (section: string, filters) => {
    if (isMigrated(section, 'CHART')) {
      return fetchReportChart(section, filters);
    } else {
      return rubyApiClient.post<any, ApiResponse<any>>(`/datalabs/${section}`, filters, {
        // @ts-ignore
        apiCallId: DATALAB_API_CALL_ID,
        transformRequest: data => JSON.stringify(data),
        transformResponse: data => data,
      });
    }
  },
  fetchTable: (section: string, filters) => {
    if (isMigrated(section, 'TABLE')) {
      return fetchReportTable(section, filters);
    } else {
      return rubyApiClient.post(`/datalabs/${section}/table`, filters, {
        // @ts-ignore
        apiCallId: DATALAB_API_CALL_ID,
        transformRequest: data => JSON.stringify(data),
        transformResponse: data => data,
      });
    }
  },
  download: (section: string, params) => {
    if (isMigrated(section, 'DOWNLOAD')) {
      return downloadReport(section, params);
    } else {
      return rubyApiClient.post(`/datalabs/${section}/download`, params, {
        responseType: 'blob',
        headers: { Accept: apiUtil.FILE_MIME_TYPES.XLSX },
        transformResponse: data => data,
        // @ts-ignore
        apiCallId: DATALAB_API_CALL_ID,
      });
    }
  },
};

const ioi = {
  submit: (offeringId, data) => rubyApiClient.post(`/iois/${offeringId}`, data),
};

const fundIoi = {
  fetchAll: offeringId => rubyApiClient.get(`/fund_iois/${offeringId}`),
  submit: (offeringId, iois, allocations) =>
    rubyApiClient.post(`/fund_iois/${offeringId}`, { iois, allocations }),
};

export type UnderwriterCreditsSubmitData = {
  allocation: UnderwriterCreditsAllocation;
  managers: UnderwriterCreditsManager[];
};
export type UnderwriterCreditsFetchResponse = {
  offering: UnderwriterCreditsOffering;
  allocation: UnderwriterCreditsAllocation;
  managers: UnderwriterCreditsManager[];
};
export type UnderwriterCreditsSubmitSuccessData = {
  data: {
    offering: UnderwriterCreditsOffering;
    allocation: UnderwriterCreditsAllocation;
    managers: UnderwriterCreditsManager[];
  };
};
const underwriterCredits = {
  fetch: (offeringId: UUID) =>
    rubyApiClient.get<UnderwriterCreditsFetchResponse>(`/underwriter_credits/${offeringId}`),
  submit: (offeringId: UUID, data: UnderwriterCreditsSubmitData) =>
    rubyApiClient.post<
      UnderwriterCreditsSubmitData,
      AxiosResponse<UnderwriterCreditsSubmitSuccessData>
    >(`/underwriter_credits/${offeringId}`, data),
};

const datalabApi = {
  // datalab
  fetchDatalab: datalab.fetchChart, // todo @deprecated
  fetchDatalabTable: datalab.fetchTable, // todo @deprecated
  downloadDatalabTable: datalab.download, // todo @deprecated

  // ioi
  saveAllocationAndIois: ioi.submit, // todo @deprecated

  // fund ioi
  fetchIoiFunds: fundIoi.fetchAll, // todo @deprecated
  updateIoiFunds: fundIoi.submit, // todo @deprecated

  // underwriter credits
  fetchUnderwriterCredits: underwriterCredits.fetch,
  submitUnderwriterCredits: underwriterCredits.submit,
};

export default datalabApi;
