import { makeFetchEntityRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { IoiTrackerPayload } from './types/Ioi';

/**
 * Get IoiTrackerData
 */
export const getFirmIoiTracker = makeFetchEntityRequest<IoiTrackerPayload>(
  dlgwApiClient,
  `/offerings/:id/ioiTracker`
);
