import { Popover, Tag } from '@cmg/common';
import { useTheme } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';
import styled from 'styled-components/macro';

import { getFormattedCurrency } from '../../shared/model/utils';
import { type Calendar_OfferingFieldsFragment } from '../graphql';

const SPriceRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const STagWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;
`;

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const PriceRangeRenderer: React.FC<Props> = ({ offering }) => {
  const {
    pricingCurrency,
    revisedIpoRangeLowUsd,
    revisedIpoRangeHighUsd,
    initialIpoRangeLowUsd,
    initialIpoRangeHighUsd,
    priceRangeLow,
    priceRangeHigh,
  } = offering.attributes || {};

  const hasIpoPriceRangeRevised = !isNil(revisedIpoRangeLowUsd) && !isNil(revisedIpoRangeHighUsd);
  const hasIpoPriceRangeInitial = !isNil(initialIpoRangeLowUsd) && !isNil(initialIpoRangeHighUsd);
  const hasRevision =
    hasIpoPriceRangeInitial &&
    hasIpoPriceRangeRevised &&
    (revisedIpoRangeLowUsd !== initialIpoRangeLowUsd ||
      revisedIpoRangeHighUsd !== initialIpoRangeHighUsd);

  const themes = useTheme();

  const hasPriceRange = !isNil(priceRangeLow) && !isNil(priceRangeHigh);

  return (
    <SPriceRangeWrapper>
      {hasRevision && hasPriceRange && (
        <Popover
          placement="top"
          variant="TOOLTIP"
          content={
            <span>
              Initial Range: {getFormattedCurrency(pricingCurrency, initialIpoRangeLowUsd)}
              {' \u2013 '}
              {getFormattedCurrency(pricingCurrency, initialIpoRangeHighUsd)}
            </span>
          }
        >
          <STagWrapper>
            <Tag color={themes.palette.info.main}>R</Tag>
          </STagWrapper>
        </Popover>
      )}
      <span>
        {hasPriceRange
          ? `${getFormattedCurrency(pricingCurrency, priceRangeLow)} \u2013
         ${getFormattedCurrency(pricingCurrency, priceRangeHigh)}`
          : '-'}
      </span>
    </SPriceRangeWrapper>
  );
};

export default PriceRangeRenderer;
